import { React, useState } from 'react';
import { Modal, Button, Col } from 'react-bootstrap';
import LeadSubmissionForm from '../LeadSubmissionForm/LeadSubmissionForm';
import LeadList from '../LeadList';

function PostLead(user) {
  const [isLeadSubmitted, setLeadSubmitted] = useState(false);
  const [showLeadSubmissionModal, setShowLeadSubmissionModal] = useState(false);

  const handleLeadSubmission = () => {
    // Update the state to trigger a re-render of LeadList
    setLeadSubmitted(true);
    setShowLeadSubmissionModal(false);
  };


  const handleModalOpen = () => {
    setShowLeadSubmissionModal(true);
  };

  const handleModalClose = () => {
    setShowLeadSubmissionModal(false);
  };

  return (
    <div className='container'>
      <div  className=" row d-flex mt-2">
        <div className="col-md-3 text-end">
          <Button variant="primary" onClick={handleModalOpen} className="ml-auto">
            Create Trade Lead
          </Button>
        </div>
        <div className='col-md-9 text-start'><h5>Lead will be visible for 7 days </h5></div>
      </div>

      <Modal show={showLeadSubmissionModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lead Submission Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LeadSubmissionForm onLeadSubmit={handleLeadSubmission} user={user} />
        </Modal.Body>
      </Modal>
      {/* <LeadSubmissionForm onLeadSubmit={handleLeadSubmission} /> */}
      <LeadList key={isLeadSubmitted} />
    </div>
  );
}

export default PostLead;