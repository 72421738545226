import React from 'react';
import { FaFacebook, FaLinkedin, FaYoutube, FaInstagram } from 'react-icons/fa';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">     
        <div className="row">
          <div className="col-md-12 text-center">
            <ul className="list-inline social-icons">
              <li className="list-inline-item">
                <a href="https://www.facebook.com/b2bleads.in">
                  <FaFacebook size={30} />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.linkedin.com/company/paranji-enterprises">
                  <FaLinkedin size={30}/>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.youtube.com/@b2bleadsindia">
                  <FaYoutube size={30} />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.instagram.com/b2bleads.in/">
                  <FaInstagram size={30} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/terms">Terms of Use</a>
              </li>
              <li className="list-inline-item">
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li className="list-inline-item">
                <a href="/cancellation">Cancellation & Refund</a>
              </li>
              <li className="list-inline-item">
                <a href="/faq">FAQ</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            &copy; 2023 B2Bleads All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
