import React, { useState }  from 'react';
import Sidebar from './Sidebar'; 
import BannerAdList from './BannerAdList';
import { Modal, Button, Col,Row } from 'react-bootstrap';
import BannerAdForm from './BannerAdForm';

function AdminBannerAd(user) {
    const [showModal, setShowModal] = useState(false);
  
    const handleOpenModal = () => {
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };


  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)', backgroundColor:'white' }}>
      <Sidebar />
      <div className="content">
      <Row className='p-3'>
        <Col md={9}>
        </Col>
        <Col md={3} >
          <Button variant="primary" onClick={handleOpenModal} className="ml-auto">
            Create Banner Ad
          </Button>
        </Col>
        </Row>
        <BannerAdList />
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Banner Ad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Your BannerAdForm component goes here */}
            <BannerAdForm onClose={handleCloseModal} user={user} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default AdminBannerAd