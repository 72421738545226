// UserDashboard.js
import React, { useEffect, useState } from 'react';
import UserEditForm from './UserEditForm';
import api from '../api';
import UserSidebar from '../UserSidebar';

function UserDashboard() {
  const [user, setUser]= useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleEditClick = () => { 
    setShowModal(true);
  };

 const fetchUser = () => {
  api.get('/user')
    .then((response) => {
      setUser(response.data);

    })
    .catch((error) => {
      console.error('Error fetching user:', error);
    });
};

  const handleSave = (updatedUser) => {
    api.put(`/users/${user.id}`, updatedUser)
      .then(() => {
        fetchUser();
        setShowModal(false);
      })
      .catch(error => console.error('Error updating user :', error));
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  useEffect(()=>{
    fetchUser()
  },[]);

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)' }}>
      <UserSidebar />
      <div className="container">
      <h5>Profile</h5>
      <div className="row justify-content-center">
        <div className="col-md-8">
        {user && (
          <>
          <table className="table table-bordered table-hover">
            <tbody>
              <tr>
                <th>Name</th>
                <td>{user.name}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{user.email}</td>
              </tr>
              <tr>
                <th>Mobile</th>
                <td>{user.mobile}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{user.address}</td>
              </tr>
              <tr>
                <th>City</th>
                <td>{user.city}</td>
              </tr>
              <tr>
                <th>Pincode</th>
                <td>{user.pincode}</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{user.country}</td>
              </tr>
              <tr>
                <th>Website</th>
                <td>{user.website}</td>
              </tr>
              <tr>
                <th>Type of Business</th>
                <td>{user.business_type}</td>
              </tr>
              <tr>
                <th>Gst No.</th>
                <td>{user.gst_no}</td>
              </tr>
              <tr>
                <th>Iec No.</th>
                <td>{user.iec_no}</td>
              </tr>
              {/* Add more user fields as needed */}
            </tbody>
          </table>
          <button className="btn btn-primary" onClick={handleEditClick}>
            Edit Profile
          </button>
          {showModal && (
            <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                  <h5>Edit Profile</h5>
                    <button type="button" className="close btn" onClick={handleCancel}>
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <UserEditForm user={user} onSave={handleSave} onCancel={handleCancel} />
                  </div>
                </div>
              </div>
            </div>
          )}
       </>
      )}
        </div>
      </div>
    </div>
    </div>
    
  );
}

export default UserDashboard;