// components/BannerAdTradeList.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import { FaTrash,FaEdit } from 'react-icons/fa';
import { ImageUrl } from '../global';
import { useAuth } from '../Context/AuthContext';
import BannerAdTrEditModal from './BannerAdTrEditModal'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parse, format } from 'date-fns';
import Pagination from './Pagination';
import ImageModal from './ImageModal';

function BannerAdTradeList() {
const {isAdmin, user} =useAuth();
const [banner_adstd, setBanner_adstd] = useState([]);
const [paymentFrequency, setPaymentFrequency] = useState('weekly'); // Default to weekly
const [selectedBanner, setSelectedBanner] = useState(null); // Track the selected banner for editing
const [showEditModal, setShowEditModal] = useState(false);
const [expirationDate, setExpirationDate] = useState(null);
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const [showImageModal, setShowImageModal] = useState(false);
const [selectedImageUrl, setSelectedImageUrl] = useState('');

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowImageModal(true);
  };


const handleEditClick = (banner) => {
  setSelectedBanner(banner);
  setShowEditModal(true);
};

const handleEditSave = (editedData) => {
  // Send a request to update the banner ad with the edited data
  api.put(`/banneradtrades/${editedData.id}`, editedData)
    .then(() => {
      // After successful update, refresh the banner ads list
      fetchBannerTradeAds(user.id);
    })
    .catch((error) => {
      console.error('Error updating banner ad:', error);
    });
};

const handlePageChange = (page) => {
  setCurrentPage(page);
};

const handleUpdateExpirationDate = (bannerAdTrId) => {
  if (expirationDate) {
    console.log(bannerAdTrId);
    const formattedDate = format(expirationDate, 'yyyy-MM-dd HH:mm:ss');
    api.patch(`/update-expiration-date-tr/${bannerAdTrId}`, { expiration_date: formattedDate })
      .then(() => {
        // Handle success if needed
        fetchBannerTradeAds();
        console.log('Expiration date updated successfully');
      })
      .catch((error) => {
        // Handle error if needed
        console.error('Error updating expiration date:', error);
      });
  }
};


// async function displayRazorpay(baneradt_id,amount) {
//   var options = {
//     "key": "rzp_live_bAbHG1YycclGNU",//"rzp_test_rp2bOy52KhJdo7", 
//     "amount": user.country.toLowerCase() !== 'india' ? (amount *  100) : (amount * 100),
//     "currency": user.country.toLowerCase() !== 'india'? "USD" : "INR",
//     "name": "B2bleads.in",
//     //"description": "Test Transaction",
//     //"image": "https://example.com/your_logo",
//     "handler": async function (response){
//       const paymentVerificationResponse = await api.post('/payment', {
//         paymentId: response.razorpay_payment_id,
//         user_id:user.id,
//         user_email: user.email,
//         currency:'Inr',
//         amount:amount,
//         method:'razorpay',
//         apiresponse:response,
//         type:'banner-trade',
//         type_id:baneradt_id,
//       });
//     },
//     "prefill": {
//         "name": user.name,
//         "email": user.email,
//         "contact": user.mobile,
//     },
//     "theme": {
//         "color": "#3399cc"
//     }
// };
// var rzp1 = new window.Razorpay(options);
// rzp1.open();
// } 

const fetchUser = () => {
  api.get('/user')
    .then((response) => {
      //setUser(response.data);
      // Call fetchBannerAds here to ensure it's called after user data is fetched
      fetchBannerTradeAds(response.data.id);
    })
    .catch((error) => {
      console.error('Error fetching user:', error);
    });
};

const fetchBannerTradeAds = (userId) =>{
 
  const apiUrl = isAdmin
      ? `/banneradtrades?page=${currentPage}&count=8`
      : `/banneradtrades?filter[user_id]=${userId}&page=${currentPage}&count=8`;

    api.get(apiUrl)
      .then((response) => {
        setBanner_adstd(response.data.data);
        setTotalPages(response.data.last_page);
      })
      .catch((error) => {
        console.error('Error fetching banner ads:', error);
      });
    
}
const handleTradeApprove = (bannerad_id) => {
  api.get(`/bannerTrade-approve/${bannerad_id}`)
    .then(() => {
      fetchBannerTradeAds();
    })
    .catch((error) => {
      console.error('Error deleting lead:', error);
    });
};

const handleLeadDelete = (bannerad_id) => {
    api.delete(`/banneradtrades/${bannerad_id}`)
      .then(() => {
        // After successful deletion, update the leads list by refetching
        fetchBannerTradeAds(user.id);
      })
      .catch((error) => {
        console.error('Error deleting ad:', error);
      });
  };

  // const handlePaymentFrequencyChange = (event) => {
  //   console.log('New payment frequency:', event.target.value);
  //   setPaymentFrequency(event.target.value);
  // };

  const confirmDelete = (banner_id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this banner ad?");
    if (isConfirmed) {
      handleLeadDelete(banner_id);
    }
  };

  
useEffect(() => {
  fetchUser();
    }, [currentPage]);


    return (
        <div className="container mb-5">
          <div className='d-flex justify-content-center'>
            <h2 className="my-4">Trade Ads</h2>
          </div>
          <div className='d-flex justify-content-center'>
            <ul className="list-group col-md-10">
              {banner_adstd.map((bannerTr) => (
                <li key={bannerTr.id} className="list-group-item">
                  <div className='row d-flex align-items-center justify-content-center'>
                    <div className='col-md-1'>
                      <img src={`${ImageUrl}/storage/tradeAds/${bannerTr.image_path}`} alt="" height='50' width='50' 
                      onClick={() => handleImageClick(`${ImageUrl}/storage/tradeAds/${bannerTr.image_path}`)}
                      style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div className='col-md-3'>
                      <h5>{bannerTr.service_industry}</h5>
                      {bannerTr.trade_lead_category}
                    </div>
                    {isAdmin && (bannerTr.status === 'Created' || bannerTr.status === 'Closed') ? (
                  <div className='col-md-1 text-center'>
                    <button className="btn btn-success btn-sm" onClick={() => handleTradeApprove(bannerTr.id)}>
                      Approve
                    </button>
                  </div>
                ) : <div className='col-md-1 p-2 text-center'>
                  {bannerTr.status === 'Closed' ? (
                          <span className="text-danger">Under Review</span>
                        ) : (
                          bannerTr.status
                        )}
                     </div>}
                    <div className="col-md-2 p-2 text-center">
                    <button
                        className="btn btn-link"
                        onClick={() => handleEditClick(bannerTr)}
                      >
                        <FaEdit fill="blue" />
                      </button>
                    <button
                        className="btn btn-link"
                        onClick={() => confirmDelete(bannerTr.id)}
                      >
                        <FaTrash fill="red" />
                      </button>
                    </div>
                    {/* <div className='col-md-1 p-2 '>
                      <select value={paymentFrequency} onChange={handlePaymentFrequencyChange}>
                        <option value="10days">10 days</option>
                        <option value="monthly">Monthly</option>
                      </select>
                    </div>
                  <div className="col-md-2 p-2 text-center">
                      {bannerTr.status === "Created" && (
                        <button className="btn btn-primary btn-sm" onClick={() => displayRazorpay(bannerTr.id, paymentFrequency === 'weekly' ? (user.country.toLowerCase() !== 'india' ? 5 : 400) : (user.country.toLowerCase() !== 'india' ? 12 : 1000))}>
                        {user.country.toLowerCase() !== 'india' ? `$${paymentFrequency === 'weekly' ? 5 : 12}` : `₹${paymentFrequency === 'weekly' ? 400 : 1000}`}
                      </button>
                  )}
                  </div> */}
                  {isAdmin && (
                  <div className="col-md-2">
                    <label htmlFor="expirationDate" className="form-label">
                      Expiration Date
                    </label>
                    <DatePicker
                      selected={parse( bannerTr.expiration_date.replace(';', ':'), 'yyyy-MM-dd HH:mm:ss', new Date())}
                      onChange={(date) => setExpirationDate(date)}
                      onBlur={() => handleUpdateExpirationDate( bannerTr.id)}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      id="expirationDate"
                    />
                  </div>
                  
                )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className='d-flex justify-content-center mt-2'>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </div>
          {selectedBanner && (
          <BannerAdTrEditModal
            bannerAd={selectedBanner}
            onSave={handleEditSave}
            onClose={() => {
              setSelectedBanner(null);
              setShowEditModal(false);
            }}
        />
      )}
      {showImageModal && (
        <ImageModal imageUrl={selectedImageUrl} onClose={() => setShowImageModal(false)} />
      )}
        </div>
      );
}

export default BannerAdTradeList;
