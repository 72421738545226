import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import api from '../api';
import Cookies from 'js-cookie';
import { ImageUrl } from '../global';
import axios from'axios';
import LoginImage from '../Images/login.jpg';
import { useAuth } from '../Context/AuthContext';

const Login = () => {
  const [formInput, setFormInput] = useState({ email: '', password: '' });
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loginMessage, setLoginMessage] = useState('');
  

  const updateFormInput = (e) => {
    e.persist();
    setFormInput((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    axios.get(`${ImageUrl}/sanctum/csrf-cookie`).then((response) => {
      api.post('/login', formInput).then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          const token  = response.data.access_token;
          Cookies.set('authToken', token, { expires: 2 });
          //onLoginSuccess();
          if(response.data.message){
            setLoginMessage(response.data.message);
          }
          const userData = response.data.user;

          if (userData) {
            login(userData);
  
            const isAdminUser = userData.is_admin || false;

            const redirectPath = sessionStorage.getItem('redirectPath');
            sessionStorage.removeItem('redirectPath'); // Clear the stored path

            if (isAdminUser) {
              navigate('/admin');
            } else {
              navigate(redirectPath || '/');
            }
          }
        }
      });
    });
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center"
    style={{
      backgroundImage: `url(${LoginImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
    }}>

    <div className="col-md-8">
    
    <div className="col-md-6 offset-md-3 border shadow p-5 "
    style={{
      backgroundColor:'white',
      borderRadius:'20px',
    }}>
      <div className='text-center'>
      <h2>Login</h2>
      </div>
      
      <form>
        <div className="mb-3">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            onChange={updateFormInput}
          />
        </div>
        <div className="mb-3">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            name="password"
            onChange={updateFormInput}
          />
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleLogin}
        >
          Login
        </button>
      </form>
      <p>
        Don't have an account? <Link to="/register">Register here</Link>
      </p>
      <p>
        Forget Password <Link to="/password-reset">Change Password</Link>
      </p>
      {loginMessage && (
      <div className="row justify-content-center">
        <div className="col-md-12">
        <div className="alert alert-danger text-center " role="alert">
          {loginMessage}
        </div>
        </div>
      </div>
        
      )}
    </div>
    
    </div>
      
      
    </div>
  );
};

export default Login;