import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';

import Buy1 from '../Images/Buy1.png';
import Buy2 from '../Images/Buy2.jpg';
import Buy3 from '../Images/Buy3.jpg';
import Buy4 from '../Images/Buy4.jpg';
import Buy5 from '../Images/Buy5.jpg';
import Sale1 from '../Images/Sale1.png';
import Sale2 from '../Images/Sale2.png';
import Sale3 from '../Images/Sale3.png';
import Sale4 from '../Images/Sale4.jpg';
import Sale5 from '../Images/Sell5.jpg';
import Distavl1 from '../Images/Distavl1.jpg';
import Distavl3 from '../Images/Distavl3.jpg';
import Distavl4 from '../Images/Distavl4.jpg';
import Distavl5 from '../Images/Distavl5.jpg';
import Export1 from '../Images/Export1.jpg';
import Export2 from '../Images/Export2.jpg';
import Export3 from '../Images/Export3.png';
import Export4 from '../Images/Export4.jpg';
import Export5 from '../Images/Export5.jpg';
import Import1 from '../Images/Import1.png';
import Import2 from '../Images/Import2.jpg';
import Import3 from '../Images/Import3.jpg';
import Import4 from '../Images/Import4.jpg';
import Import5 from '../Images/Import5.jpg';


function SampleBanner() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div class="container">

  <h1 class="fw-light text-center text-lg-start mt-4 mb-0">Sample Banner Images</h1>

  <hr class="mt-2 mb-5" />

  <div class="row text-center text-lg-start">

    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Buy1)}>
        <img class="img-fluid img-thumbnail" src={Buy1} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Buy2)}>
        <img class="img-fluid img-thumbnail" src={Buy2} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Buy3)}>
        <img class="img-fluid img-thumbnail" src={Buy3} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Buy4)}>
        <img class="img-fluid img-thumbnail" src={Buy4} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Buy5)}>
        <img class="img-fluid img-thumbnail" src={Buy5} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Sale1)}>
        <img class="img-fluid img-thumbnail" src={Sale1} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Sale2)}>
        <img class="img-fluid img-thumbnail" src={Sale2} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Sale3)}>
        <img class="img-fluid img-thumbnail" src={Sale3} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Sale4)}>
        <img class="img-fluid img-thumbnail" src={Sale4} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Sale5)}>
        <img class="img-fluid img-thumbnail" src={Sale5} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Distavl1)}>
        <img class="img-fluid img-thumbnail" src={Distavl1} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Distavl3)}>
        <img class="img-fluid img-thumbnail" src={Distavl3} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Distavl4)}>
        <img class="img-fluid img-thumbnail" src={Distavl4} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Distavl5)}>
        <img class="img-fluid img-thumbnail" src={Distavl5} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Export1)}>
        <img class="img-fluid img-thumbnail" src={Export1} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Export2)}>
        <img class="img-fluid img-thumbnail" src={Export2} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Export3)}>
        <img class="img-fluid img-thumbnail" src={Export3} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Export4)}>
        <img class="img-fluid img-thumbnail" src={Export4} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Export5)}>
        <img class="img-fluid img-thumbnail" src={Export5} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Import1)}>
        <img class="img-fluid img-thumbnail" src={Import1} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Import2)}>
        <img class="img-fluid img-thumbnail" src={Import2} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Import3)}>
        <img class="img-fluid img-thumbnail" src={Import3} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Import4)}>
        <img class="img-fluid img-thumbnail" src={Import4} alt="" />
      </a>
    </div>
    <div class="col-lg-3 col-md-4 col-6">
      <a href="#" class="d-block mb-4 h-100" onClick={() => handleImageClick(Import5)}>
        <img class="img-fluid img-thumbnail" src={Import5} alt="" />
      </a>
    </div>
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Body>
        <img src={selectedImage} alt="Zoomed In" className="img-fluid" />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleCloseModal}>
          Close
        </button>
      </Modal.Footer>
    </Modal>

  </div>

</div>
  )
}

export default SampleBanner