import React, { useState, useRef } from 'react';
import api from '../api';
import Swal from 'sweetalert2';
import { useAuth } from '../Context/AuthContext';

function BannerAdForm({ onBannerSubmit }) {

  const {isAdmin, user} =useAuth();
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [paymentOption, setPaymentOption] = useState('10days');
  const [validationError, setValidationError] = useState('');
  const imagePreviewRef = useRef(null);

  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (validateImageSize(file) && validateImageDimensions(file)) {
      setSelectedImage(file);
      setValidationError('');
    } else {
      setSelectedImage(null);
      setValidationError('Invalid image file. Please upload an image with the correct dimensions and file size.');
    }
  };

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  const validateImageSize = (file) => {
    return file.size <= 500 * 1024; // 500KB (500 * 1024 bytes)
  };

  const validateImageDimensions = (file) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    return new Promise((resolve, reject) => {
      img.onload = () => {
        if (img.width === 728 && img.height === 90) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      img.onerror = () => {
        reject();
      };
    });
  };

  const handlePreview = () => {
    if (selectedImage) {
      imagePreviewRef.current.src = URL.createObjectURL(selectedImage);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedIndustry && selectedImage) {
      
        const bannerAd ={
          "service_industry" :selectedIndustry,
          "image_path" : selectedImage,
          "user_id" : user.user,
        };
        await api.post('/bannerads', bannerAd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            // Handle the response here
           Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Thank you for your Banner Ad. It will be uploaded in 24 hrs.',
            });
            onBannerSubmit()
          })
          .catch((error) => {
            // Handle any errors here
            console.error('An error occurred while submitting the lead:', error);
          });
      //     if(isAdmin){
      // }
      // else {
      // const amount = calculatePaymentAmount(paymentOption);
      
      //     var options = {
      //       "key": "rzp_live_bAbHG1YycclGNU",//"rzp_test_rp2bOy52KhJdo7", //
      //       "amount": user.country.toLowerCase() !== 'india' ? (amount *  100) : (amount * 100), // Convert amount to dollars if not from India
      //       "currency": user.country.toLowerCase() !== 'india'? "USD" : "INR",
      //       "name": "B2bleads.in",
      //       //"description": "Test Transaction",
      //       //"image": "https://example.com/your_logo",
      //       "handler":async function (response){
      //         const bannerAd = {
      //           service_industry: selectedIndustry,
      //           image_path: selectedImage,
      //           user_id: user.user,
      //           paymentAmount: amount,
      //         };
      //         try {
      //           const postresponse = await api.post('/bannerads', bannerAd, {
      //             headers: {
      //               'Content-Type': 'multipart/form-data',
      //             },
      //           });
      //           console.log("post response",postresponse.data);
      //           const paymentVerificationResponse = await api.post('/payment', {
      //             paymentId: response.razorpay_payment_id,
      //             user_id:user.id,
      //             user_email: user.email,
      //             currency:'Inr',
      //             amount:amount,
      //             method:'razorpay',
      //             apiresponse:response,
      //             type:'banner-ad',
      //             type_id:postresponse.data.bannerad.id,
      //           });
      //           Swal.fire({
      //             icon: 'success',
      //             title: 'Success!',
      //             text: 'Thank you for your Banner Ad. It will be uploaded in 24 hrs.',
      //           });
      //           onBannerSubmit();
      //           event.target.reset();
      //           setSelectedIndustry('');
      //           setSelectedImage(null);
      //         } catch (error) {
      //           console.error('An error occurred while submitting the banner ad:', error);
      //         }
                
      //       },
      //       "prefill": {
      //           "name": user.name,
      //           "email": user.email,
      //           "contact": user.mobile,
      //       },
      //       "theme": {
      //           "color": "#3399cc"
      //       }
      //   };
      //   var rzp1 = new window.Razorpay(options);
      //   rzp1.open();
      // }
    } else {
      alert(validationError || 'Please select an industry and a valid image file.');
    }
  };

  const calculatePaymentAmount = (option) => {
    if(user.country.toLowerCase() !== 'india'){
      return option === '10days' ? 5 : 12;
    }
    else {
      return option === '10days' ? 200 : 500;
    }
  };

  return (
    <div className="container mb-5">
      <div className="row justify-content-center">
        <div>
          <h2 className="text-center mt-2">Submit a Banner Ad (Service Industries)</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="industry">Select Industry:</label>
              <select id="industry" className="form-select" value={selectedIndustry} onChange={handleIndustryChange} required>
                <option value="" disabled>Select an industry</option>
                <option value="Ac repairing & Maintance services">Ac repairing & Maintance services</option>
                <option value="Accountant services (A-Z)">Accountant services (A-Z)</option>
                <option value="Yoga classes ( A-Z city wise)">Yoga classes ( A-Z city wise)</option>
                <option value="Zinc electroplating services">Zinc electroplating services</option>
                <option value="Acoustic services & consultant">Acoustic services & consultant</option>
              <option value="Acp elevations/installation service">Acp elevations/installation service</option>
              <option value="Acquisitions advisory services (A-Z)">Acquisitions advisory services (A-Z)</option>
              <option value="Acrylic cutting & printing  service">Acrylic cutting & printing  service</option>
              <option value="Ad agency (A-Z category)">Ad agency (A-Z category)</option>
              <option value="Address verification services">Address verification services</option>
              <option value="Adhesive coating services">Adhesive coating services</option>
              <option value="Advertisement designing services">Advertisement designing services</option>
              <option value="Advisory services">Advisory services</option>
              <option value="Aerial photography">Aerial photography</option>
              <option value="Aerosol coatings">Aerosol coatings</option>
              <option value="Agricultural consultant">Agricultural consultant</option>
              <option value="Air cargo/courier services (A-Z services)">Air cargo/courier services (A-Z services)</option>
              <option value="Air compressor rental">Air compressor rental</option>
              <option value="Air conditioning ducting services">Air conditioning ducting services</option>
              <option value="Air pollution consultants">Air pollution consultants</option>
              <option value="Air testing services">Air testing services</option>
              <option value="Alloys testing services">Alloys testing services</option>
              <option value="Aluminium sign boards">Aluminium sign boards</option>
              <option value="Aluminum anodizing services">Aluminum anodizing services</option>
              <option value="Aluminum fabrication services (Cutting/welding/partition works)">Aluminum fabrication services (Cutting/welding/partition works)</option>
              <option value="Ambulance fabrication services">Ambulance fabrication services</option>
              <option value="Ambulance services">Ambulance services</option>
              <option value="Analytical testing services">Analytical testing services</option>
              <option value="Android app development service">Android app development service</option>
              <option value="Animation design services">Animation design services</option>
              <option value="Anodizing services">Anodizing services</option>
              <option value="Anti corrosion coating services">Anti corrosion coating services</option>
              <option value="Apartment interior design services">Apartment interior design services</option>
              <option value="Apparel designing services">Apparel designing services</option>
              <option value="Application maintenance services">Application maintenance services</option>
              <option value="Arbitration services provider">Arbitration services provider</option>
              <option value="Architect consultants/ designers">Architect consultants/ designers</option>
              <option value="Audio & Video Recording Services">Audio & Video Recording Services</option>
              <option value="Audit firms/consulting services">Audit firms/consulting services</option>
              <option value="Band services">Band services</option>
              <option value="Banner design">Banner design</option>
              <option value="Bathroom designing company & Service">Bathroom designing company & Service</option>
              <option value="Beautician course/ services">Beautician course/ services</option>
              <option value="Bioanalytical services">Bioanalytical services</option>
              <option value="Boiler Erection Services">Boiler Erection Services</option>
              <option value="Bonded warehouse solution">Bonded warehouse solution</option>
              <option value="Book publishing services">Book publishing services</option>
              <option value="Book translation services">Book translation services</option>
              <option value="Brass cutting service">Brass cutting service</option>
              <option value="Broadcast solutions">Broadcast solutions</option>
              <option value="Building interior solutions">Building interior solutions</option>
              <option value="Building Lifting & Shifting Services">Building Lifting & Shifting Services</option>
              <option value="Bulk cargo services">Bulk cargo services</option>
              <option value="Bulk email services">Bulk email services</option>
              <option value="Bulk sms services">Bulk sms services</option>
              <option value="Business management consultants">Business management consultants</option>
              <option value="Business marketing services">Business marketing services</option>
              <option value="C&F agency services">C&F agency services</option>
              <option value="Cable testing services">Cable testing services</option>
              <option value="CAD CAM Design Services">CAD CAM Design Services</option>
              <option value="Calibration services">Calibration services</option>
              <option value="Calibration testing services">Calibration testing services</option>
              <option value="Cam designing solution">Cam designing solution</option>
              <option value="Camera Installation Services">Camera Installation Services</option>
              <option value="Car decoration services">Car decoration services</option>
              <option value="Car rental services">Car rental services</option>
              <option value="Car repair/Washing services">Car repair/Washing services</option>
              <option value="Car transportation agent">Car transportation agent</option>
              <option value="Cargo service providers  A to Z">Cargo service providers  A to Z</option>
              <option value="Carpet flooring contractors">Carpet flooring contractors</option>
              <option value="Carton printing services">Carton printing services</option>
              <option value="Cartridge refilling services">Cartridge refilling services</option>
              <option value="Cctv Camera Installation Services">Cctv Camera Installation Services</option>
              <option value="Civil engineering companies">Civil engineering companies</option>
              <option value="Cloud hosting services">Cloud hosting services</option>
              <option value="Cnc job work">Cnc job work</option>
              <option value="Coaching Institutes">Coaching Institutes</option>
              <option value="Coating service provider">Coating service provider</option>
              <option value="Cold storage warehouse/ services">Cold storage warehouse/ services</option>
              <option value="Commercial Building / Construction  Services">Commercial Building / Construction  Services</option>
              <option value="Computer & Mobile - repair & services">Computer & Mobile - repair & services</option>
              <option value="Concrete Mixer Rental">Concrete Mixer Rental</option>
              <option value="Construction Equipment Rental">Construction Equipment Rental</option>
              <option value="Container Lashing Services">Container Lashing Services</option>
              <option value="Courier Industries/ Services">Courier Industries/ Services</option>
              <option value="Dairy farm consultants">Dairy farm consultants</option>
              <option value="Dance Training Schools">Dance Training Schools</option>
              <option value="Data entry agency">Data entry agency</option>
              <option value="Day care services">Day care services</option>
              <option value="Decorators / Contractors">Decorators / Contractors</option>
              <option value="detective agencies (City wise)">detective agencies (City wise)</option>
              <option value="Digital advertising/Photography agencies">Digital advertising/Photography agencies</option>
              <option value="Distribution management services">Distribution management services</option>
              <option value="Dj Party Service">Dj Party Service</option>
              <option value="Door to door parcel service">Door to door parcel service</option>
              <option value="E commerce application development services">E commerce application development services</option>
              <option value="E commerce website designing services">E commerce website designing services</option>
              <option value="Earth moving services">Earth moving services</option>
              <option value="Earthwork contractor">Earthwork contractor</option>
              <option value="Education, Training and coching center">Education, Training and coching center</option>
              <option value="Electrical consultancy service">Electrical consultancy service</option>
              <option value="Elevator servicing">Elevator servicing</option>
              <option value="Email data recovery">Email data recovery</option>
              <option value="Email marketing company">Email marketing company</option>
              <option value="Energy conservation services">Energy conservation services</option>
              <option value="Engineering consulting companies ( A-Z services)">Engineering consulting companies ( A-Z services)</option>
              <option value="Environmental consultants">Environmental consultants</option>
              <option value="Environmental testing services">Environmental testing services</option>
              <option value="Erection services (A-Z category)">Erection services (A-Z category)</option>
              <option value="Erp software development">Erp software development</option>
              <option value="Event management services">Event management services</option>
              <option value="Excavation project/ rental service">Excavation project/ rental service</option>
              <option value="Exhibition advertising services (A-Z category)">Exhibition advertising services (A-Z category)</option>
              <option value="Export/Import consultants">Export/Import consultants</option>
              <option value="Exterior architect designing">Exterior architect designing</option>
              <option value="Fabrication companies (A-Z category)">Fabrication companies (A-Z category)</option>
              <option value="Facade cleaning services">Facade cleaning services</option>
              <option value="Facility management services">Facility management services</option>
              <option value="Factory shifting services">Factory shifting services</option>
              <option value="False ceiling/flooring services (A-Z services)">False ceiling/flooring services (A-Z services)</option>
              <option value="Fancy dress hire">Fancy dress hire</option>
              <option value="Farm consultants">Farm consultants</option>
              <option value="Farmhouse landscaping services">Farmhouse landscaping services</option>
              <option value="Fashion designing/school services (A-Z service)">Fashion designing/school services (A-Z service)</option>
              <option value="Fda registration services">Fda registration services</option>
              <option value="Fencing services">Fencing services</option>
              <option value="Finance consultancy services">Finance consultancy services</option>
              <option value="Fire fighting turnkey projects">Fire fighting turnkey projects</option>
              <option value="Fire protection (safety) inspection services">Fire protection (safety) inspection services</option>
              <option value="Flex printing services">Flex printing services</option>
              <option value="Flower decoration service">Flower decoration service</option>
              <option value="Food catering services">Food catering services</option>
              <option value="Food processing Services/consultants">Food processing Services/consultants</option>
              <option value="Food testing laboratories">Food testing laboratories</option>
              <option value="Foreign exchange agency">Foreign exchange agency</option>
              <option value="Forklift services">Forklift services</option>
              <option value="Furniture works services">Furniture works services</option>
              <option value="Galvanizing services">Galvanizing services</option>
              <option value="Gardening services (A-Z services)">Gardening services (A-Z services)</option>
              <option value="Garment dyeing services">Garment dyeing services</option>
              <option value="Gemstone consultants">Gemstone consultants</option>
              <option value="General home appliances services (Rent, repair etc)">General home appliances services (Rent, repair etc)</option>
              <option value="Generator repair, rent services">Generator repair, rent services</option>
              <option value="Geotechnical engineering">Geotechnical engineering</option>
              <option value="Glass & accessories works services">Glass & accessories works services</option>
              <option value="Glazing services (A-Z services)">Glazing services (A-Z services)</option>
              <option value="Gps tracking services">Gps tracking services</option>
              <option value="Granite flooring (A-Z services )">Granite flooring (A-Z services )</option>
              <option value="Graphic designing courses">Graphic designing courses</option>
              <option value="Gravure printing service">Gravure printing service</option>
              <option value="Grills fabrication works">Grills fabrication works</option>
              <option value="Hair transplant services">Hair transplant services</option>
              <option value="Hard disk recovery/repairing services">Hard disk recovery/repairing services</option>
              <option value="Health care services">Health care services</option>
              <option value="Heat reflective coating services">Heat reflective coating services</option>
              <option value="Heat treatment services">Heat treatment services</option>
              <option value="Heavy embroidery work">Heavy embroidery work</option>
              <option value="Heavy engineering works">Heavy engineering works</option>
              <option value="Heavy equipment erection">Heavy equipment erection</option>
              <option value="Heavy machining job work">Heavy machining job work</option>
              <option value="Heavy metal testing services">Heavy metal testing services</option>
              <option value="Herbal consultant">Herbal consultant</option>
              <option value="Hoarding advertising agencies">Hoarding advertising agencies</option>
              <option value="Home interior decorator">Home interior decorator</option>
              <option value="Home loan consultants">Home loan consultants</option>
              <option value="Home security systems">Home security systems</option>
              <option value="Homeopathy treatment">Homeopathy treatment</option>
              <option value="Horticultural services">Horticultural services</option>
              <option value="Hospital consultants">Hospital consultants</option>
              <option value="Hospital management services">Hospital management services</option>
              <option value="Hospitality consultants">Hospitality consultants</option>
              <option value="Hotel construction services">Hotel construction services</option>
              <option value="Hotel housekeeping services">Hotel housekeeping services</option>
              <option value="Hotel interior design services">Hotel interior design services</option>
              <option value="Hotel management services">Hotel management services</option>
              <option value="House shifting services (A-Z)">House shifting services (A-Z)</option>
              <option value="Housekeeping Services">Housekeeping Services</option>
              <option value="Hr consultancy services">Hr consultancy services</option>
              <option value="Hvac fabrication/Maintenance services">Hvac fabrication/Maintenance services</option>
              <option value="Hydraulic crane rental services">Hydraulic crane rental services</option>
              <option value="Hydraulic cylinder repair services">Hydraulic cylinder repair services</option>
              <option value="Hydraulic pump repair">Hydraulic pump repair</option>
              <option value="Image processing service">Image processing service</option>
              <option value="Import export services">Import export services</option>
              <option value="Inbound call center services">Inbound call center services</option>
              <option value="Inbound telemarketing services">Inbound telemarketing services</option>
              <option value="Income tax consultants">Income tax consultants</option>
              <option value="Indoor pest control services">Indoor pest control services</option>
              <option value="Industrial air conditioning service">Industrial air conditioning service</option>
              <option value="Industrial automation service">Industrial automation service</option>
              <option value="Industrial cleaning services">Industrial cleaning services</option>
              <option value="Industrial construction service (A-Z)">Industrial construction service (A-Z)</option>
              <option value="Industrial consultancy services">Industrial consultancy services</option>
              <option value="Industrial photography services">Industrial photography services</option>
              <option value="Industrial related service">Industrial related service</option>
              <option value="Instrument Calibration & Instrument Testing Services">Instrument Calibration & Instrument Testing Services</option>
              <option value="Insurance Services (A-Z category)">Insurance Services (A-Z category)</option>
              <option value="Interior design services (A-Z)">Interior design services (A-Z)</option>
              <option value="Internal audit services">Internal audit services</option>
              <option value="International air/ship cargo services">International air/ship cargo services</option>
              <option value="Internet marketing services (A-Z)">Internet marketing services (A-Z)</option>
              <option value="Investment consultant services (A-Z)">Investment consultant services (A-Z)</option>
              <option value="Label stickers equipments & printing services">Label stickers equipments & printing services</option>
              <option value="Laboratory consultants & testing service">Laboratory consultants & testing service</option>
              <option value="Language translation services">Language translation services</option>
              <option value="Laptop repairing services center">Laptop repairing services center</option>
              <option value="Laser cutting job work">Laser cutting job work</option>
              <option value="Lathe machine repair services">Lathe machine repair services</option>
              <option value="Law consultancy services">Law consultancy services</option>
              <option value="Leather engraving services">Leather engraving services</option>
              <option value="Leather testing services">Leather testing services</option>
              <option value="Led advertising board providers">Led advertising board providers</option>
              <option value="Legal advisor services (A-Z category)">Legal advisor services (A-Z category)</option>
              <option value="License certification services (FSSAI, GST, Halal, GMP, Khosher,ISO,IEC,Haccp etc)">License certification services (FSSAI, GST, Halal, GMP, Khosher,ISO,IEC,Haccp etc)</option>
              <option value="Lift installation repair services & maintenance">Lift installation repair services & maintenance</option>
              <option value="Liquid cargo services">Liquid cargo services</option>
              <option value="Litigation consultant">Litigation consultant</option>
              <option value="Local newspaper advertising services">Local newspaper advertising services</option>
              <option value="Local radio advertising">Local radio advertising</option>
              <option value="Local tv advertising">Local tv advertising</option>
              <option value="Machineries related services (Sale/repaire/rent/erection/loan etc)">Machineries related services (Sale/repaire/rent/erection/loan etc)</option>
              <option value="Make-up artistry (Bridal make up)">Make-up artistry (Bridal make up)</option>
              <option value="Management consulting services">Management consulting services</option>
              <option value="Material & Product Testing Labs and Testing Services">Material & Product Testing Labs and Testing Services</option>
              <option value="Material handling companies">Material handling companies</option>
              <option value="Mechanical engineering solution (A-Z services)">Mechanical engineering solution (A-Z services)</option>
              <option value="Mechanical job works">Mechanical job works</option>
              <option value="Media advertising services">Media advertising services</option>
              <option value="Mehendi designer in my city">Mehendi designer in my city</option>
              <option value="Mergers and Acquisitions">Mergers and Acquisitions</option>
              <option value="Metal related services (A-Z category)">Metal related services (A-Z category)</option>
              <option value="Milling job works">Milling job works</option>
              <option value="Mobile advertising agencies">Mobile advertising agencies</option>
              <option value="Mobile application development service">Mobile application development service</option>
              <option value="Modular kitchen services (City wise)">Modular kitchen services (City wise)</option>
              <option value="Mold design services">Mold design services</option>
              <option value="Motor repair services (City wise)">Motor repair services (City wise)</option>
              <option value="MRI scan center (City wise)">MRI scan center (City wise)</option>
              <option value="Music shows (City wise)">Music shows (City wise)</option>
              <option value="Mutual fund consultants">Mutual fund consultants</option>
              <option value="Nabl accredited labs">Nabl accredited labs</option>
              <option value="Network security services">Network security services</option>
              <option value="Nickel chrome plating services">Nickel chrome plating services</option>
              <option value="Nursing homes ( City wise)">Nursing homes ( City wise)</option>
              <option value="Offset printing services">Offset printing services</option>
              <option value="Organic farming consultants">Organic farming consultants</option>
              <option value="Packer & movers in my city">Packer & movers in my city</option>
              <option value="Passport & Visa Services (City Wise)">Passport & Visa Services (City Wise)</option>
              <option value="Payroll software services (A-Z category)">Payroll software services (A-Z category)</option>
              <option value="Pets boarding services (City wise)">Pets boarding services (City wise)</option>
              <option value="PF /ESI advisers">PF /ESI advisers</option>
              <option value="Pharma project consultants">Pharma project consultants</option>
              <option value="Photography services (A-Z sectors)">Photography services (A-Z sectors)</option>
              <option value="Piston pump repairing services">Piston pump repairing services</option>
              <option value="Plumbering service">Plumbering service</option>
              <option value="Polyhouse construction services">Polyhouse construction services</option>
              <option value="Pool maintenance services">Pool maintenance services</option>
              <option value="Printing service">Printing service</option>
              <option value="Public notary services (City wise)">Public notary services (City wise)</option>
              <option value="Quality certification services">Quality certification services</option>
              <option value="Quality control services">Quality control services</option>
              <option value="Quality Surveyors">Quality Surveyors</option>
              <option value="Rail cargo services">Rail cargo services</option>
              <option value="Railing fabrication companies">Railing fabrication companies</option>
              <option value="Railway advertising agency">Railway advertising agency</option>
              <option value="Railway cargo agents">Railway cargo agents</option>
              <option value="Refrigerated warehousing services">Refrigerated warehousing services</option>
              <option value="Residential Maintenance Services">Residential Maintenance Services</option>
              <option value="Resort services">Resort services</option>
              <option value="Restaurants (Buffet Dinning)">Restaurants (Buffet Dinning)</option>
              <option value="Risk advisory & management services">Risk advisory & management services</option>
              <option value="Roofing works">Roofing works</option>
              <option value="Safety audit services">Safety audit services</option>
              <option value="Sales tax consultancy (GST)">Sales tax consultancy (GST)</option>
              <option value="Sanitary contractors">Sanitary contractors</option>
              <option value="Scaffolding on hire">Scaffolding on hire</option>
              <option value="Sea cargo agency (A-Z services)">Sea cargo agency (A-Z services)</option>
              <option value="Security services (A-Z sectors)">Security services (A-Z sectors)</option>
              <option value="Seo companies">Seo companies</option>
              <option value="Server maintenance services">Server maintenance services</option>
              <option value="Sheet metal fabrication services">Sheet metal fabrication services</option>
              <option value="Shipping C&F agent service">Shipping C&F agent service</option>
              <option value="Showroom interior decorator">Showroom interior decorator</option>
              <option value="Signage ads service providers">Signage ads service providers</option>
              <option value="Skill development Training center">Skill development Training center</option>
              <option value="Software training centre">Software training centre</option>
              <option value="Soil testing services">Soil testing services</option>
              <option value="Sports Products & related services provider">Sports Products & related services provider</option>
              <option value="Stainless steel metal fabrications">Stainless steel metal fabrications</option>
              <option value="Stall exhibition/Designing advertising">Stall exhibition/Designing advertising</option>
              <option value="Structural design service provider">Structural design service provider</option>
              <option value="Swimming pools construction services">Swimming pools construction services</option>
              <option value="Tax audit services">Tax audit services</option>
              <option value="Textile design services/agency">Textile design services/agency</option>
              <option value="Textile dyeing/print services">Textile dyeing/print services</option>
              <option value="Tin electroplating">Tin electroplating</option>
              <option value="Toner refilling services">Toner refilling services</option>
              <option value="Tool design services">Tool design services</option>
              <option value="Tour operators/packages services">Tour operators/packages services</option>
              <option value="Tourist visa services">Tourist visa services</option>
              <option value="Tower construction service">Tower construction service</option>
              <option value="Trade Directories">Trade Directories</option>
              <option value="Trade fair organisers india">Trade fair organisers india</option>
              <option value="Trademark consultancy services">Trademark consultancy services</option>
              <option value="Training Consultants">Training Consultants</option>
              <option value="Translators & Interpreters (languages)">Translators & Interpreters (languages)</option>
              <option value="Transportation Services">Transportation Services</option>
              <option value="Truck transportation services">Truck transportation services</option>
              <option value="Tv advertisements agency">Tv advertisements agency</option>
              <option value="Vacuum packaging services">Vacuum packaging services</option>
              <option value="Valve repair services">Valve repair services</option>
              <option value="Van body fabrication service">Van body fabrication service</option>
              <option value="Vegetable cold storage">Vegetable cold storage</option>
              <option value="Vehicle tracking service">Vehicle tracking service</option>
              <option value="Vendor audit services">Vendor audit services</option>
              <option value="Verification services">Verification services</option>
              <option value="Video production services">Video production services</option>
              <option value="Visa consultants services">Visa consultants services</option>
              <option value="Wall decorating (A-Z services)">Wall decorating (A-Z services)</option>
              <option value="Warehouse ( Mgmt/rental/storage service providers)">Warehouse ( Mgmt/rental/storage service providers)</option>
              <option value="Water ( Analysis, cooling treatment ) service providers">Water ( Analysis, cooling treatment ) service providers</option>
              <option value="Wealth management services">Wealth management services</option>
              <option value="Web application development services">Web application development services</option>
              <option value="Web designing services">Web designing services</option>
              <option value="Wedding blouse/saree designer (Tailoring) service">Wedding blouse/saree designer (Tailoring) service</option>
              <option value="Wedding caterers services">Wedding caterers services</option>
              <option value="Wedding event management services">Wedding event management services</option>
              <option value="Wedding lighting services">Wedding lighting services</option>
              <option value="Wedding photography services">Wedding photography services</option>
              <option value="Welding works">Welding works</option>
              <option value="Wet mix macadam plant on hire">Wet mix macadam plant on hire</option>
              <option value="Wine shops in my city">Wine shops in my city</option>
              <option value="Wireless security camera system">Wireless security camera system</option>
              <option value="Wooden flooring services (A-Z)">Wooden flooring services (A-Z)</option>
              <option value="Wooden furniture designer service">Wooden furniture designer service</option>
              <option value="Xerox machine on hire">Xerox machine on hire</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="image">Banner Image (728x90 pixels, max 500KB):</label>
              <input
                type="file"
                id="image"
                className="form-control"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="paymentOption">Select Payment Option:</label>
              {
                (user.country.toLowerCase() !== 'india') ? (
                  <select id="paymentOption" className="form-select" value={paymentOption} onChange={handlePaymentOptionChange} required>
                    <option value="10days">10 Days ($5)</option>
                    <option value="monthly">Monthly ($12)</option>
                  </select>
                ) : (
                  <select id="paymentOption" className="form-select" value={paymentOption} onChange={handlePaymentOptionChange} required>
                    <option value="10days">10 Days (Rs.200)</option>
                    <option value="monthly">Monthly (Rs.500)</option>
                  </select>
                )
              }
            </div> */}
            {validationError && <div className="text-danger">{validationError}</div>}
            <div className="row mb-3">
              <div className="col-6">
                <button type="button" className="btn btn-primary" onClick={handlePreview}>Preview</button>
              </div>
              <div className="col-6 text-end">
                {
                  isAdmin ? <button type="submit" className="btn btn-success">Submit</button>
                  : <button type="submit" className="btn btn-success">Submit</button>
                }
                
              </div>
            </div>
          </form>
          {selectedImage && <img ref={imagePreviewRef} alt="Preview" style={{ maxWidth: '100%' }} className="mt-3" />}
        </div>
      </div>
    </div>
  );
}

export default BannerAdForm;
