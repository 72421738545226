// VisitorCounter.js
import React, { useEffect, useState } from 'react';
import api from '../api';

const Counter = () => {
  const [visitorCount, setVisitorCount] = useState(0);

  useEffect(() => {
    // Fetch the initial visitor count
    fetchVisitorCount();

    // Increment the count on component mount
    incrementVisitorCount();
  }, []);

  const fetchVisitorCount = async () => {
    try {
      const response = await api.get('/visitor');
      setVisitorCount(response.data.count);
    } catch (error) {
      console.error('Error fetching visitor count:', error);
    }
  };

  const incrementVisitorCount = async () => {
    try {
      await api.post('/increment');
    } catch (error) {
      console.error('Error incrementing visitor count:', error);
    }
  };

  return (
    <div className="container">
        <div className='col-md-2'>
        <div className="card">
        <div className="card-body">
        <h5 className="card-title">Visitor Count</h5>
        <p className="card-text">Current count: {visitorCount}</p>
        {/* <button className="btn btn-primary" onClick={incrementVisitorCount}>
            Increment Count
        </button> */}
        </div>
        </div>
        </div>
    </div>
    
  );
};

export default Counter;
