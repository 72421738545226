import React from 'react';
import ReactGA from 'react-ga';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import Home from './Components/Home/Home';
import Advertise from './Components/Advertise/Advertise';
import Contact from './Components/Contact/Contact';
import Navbar from './Components/Navbar/Navbar';
import NavBarSecondary from './Components/Navbar/NavBarSecondary';
import ConditionalFooter from './Components/ConditionalFooter';
import PostLead from './Components/PostLead/PostLead';
import Login from './Components/Login';
import Logout from './Components/Logout';
import RegistrationForm from './Components/RegisterForm/Register';
import PrivateRoute from './Components/PrivateRoute';
import Buy from './Components/Buy';
import Sell from './Components/Sell';
import Distributor from './Components/Distributor';
import Export from './Components/Export';
import Import from './Components/Import';
import PricingPage from './Components/PricingPage';
import AdminDashboard from './Components/AdminDashboard';
import api from './api'
import NotFound from './Components/NotFound';
import { useAuth } from './Context/AuthContext';
import AdminBannerAd from './Components/AdminBannerAd';
import AdminbannerTradeAd from './Components/AdminbannerTradeAd';
import AdminLead from './Components/AdminLead';
import SampleBanner from './Components/SampleBanner';
import DistribWanted from './Components/DistribWanted';
import Terms from './Components/Terms';
import Privacy from './Components/Privacy';
import Cancelleation from './Components/Cancelleation';
import UserDashboard from './Components/UserDashboard';
import ForgotPassword from './Components/ForgotPassword';
import ResetPassword from './Components/ResetPassword';
import Counter from './Components/Counter';
import AdminUsers from './Components/AdminUsers';
import FAQPage from './Components/FAQPage';
import UserAds from './Components/UserAds';
import UserPostlead from './Components/UserPostlead';
import UserTradAds from './Components/UserTradAds';
import ChangePassword from './Components/ChangePassword';


function App() {
  const { isAuthenticated, isAdmin } = useAuth();
  const [user, setUser] = useState(null);

  ReactGA.initialize('UA-303177547-1');
  const fetchUser = async () => {
    try {
        const response = await api.get('/user');
        setUser(response.data.id);
    } catch (error) {
        console.error('Error fetching user:', error);
    }
};
// const location = useLocation();
    useEffect(() => {
      
        fetchUser();
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <Router>
          <div className="content">
          {!isAdmin && <Navbar isAuthenticated={isAuthenticated} />}
          {!isAdmin && <NavBarSecondary isAuthenticated={isAuthenticated}  /> }
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/advertise" element={<PrivateRoute isAuthenticated={isAuthenticated} element={<Advertise user = {user} />} />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/pricing" element={<PricingPage />}/> */}
            <Route path="/buy" element={<Buy />} />
            <Route path="/sell" element={<Sell />} />
            <Route path="/distributor" element={<Distributor />} />
            <Route path="/dist-wanted" element={<DistribWanted/>} />
            <Route path="/import" element={<Import />} />
            <Route path="/export" element={<Export />} />
            <Route path="/login" element={<Login   />} />
            <Route path="/register" element={<RegistrationForm />} />
            <Route path="/counter" element={<Counter/>} />
            <Route path="/banner-ads" element={<SampleBanner />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy/>} />
            <Route path="/password-reset" element={<ForgotPassword />} />
            <Route path="/pass-reset" element={<ResetPassword />} />
            <Route path="faq" element={<FAQPage />} />
            <Route path="/cancellation" element={<Cancelleation />} />
            <Route path="/logout" element={<Logout />} />
            
          <Route
            path="/postlead"
            element={<PrivateRoute isAuthenticated={isAuthenticated} element={<PostLead  user ={user}/>}  />}
          />
          <Route
            path="/user"
            element={<PrivateRoute isAuthenticated={isAuthenticated} element={<UserDashboard />}  />}
          />
          <Route
            path="/user/lead"
            element={<PrivateRoute isAuthenticated={isAuthenticated} element={<UserPostlead user ={user}/>}  />}
          />
          <Route
            path="/user/banner"
            element={<PrivateRoute isAuthenticated={isAuthenticated} element={<UserAds user ={user}/>}  />}
          />
          <Route
            path="/user/banner-trade"
            element={<PrivateRoute isAuthenticated={isAuthenticated} element={<UserTradAds  user ={user}/>}  />} 
          />
          <Route
            path="/user/pwd-ch"
            element={<PrivateRoute isAuthenticated={isAuthenticated} element={<ChangePassword />}  />}
          />
          <Route
            path="/admin"
            element={isAdmin ? <AdminDashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin/postlead"
            element={isAdmin ? <AdminLead user ={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin/banner-ad"
            element={isAdmin ? <AdminBannerAd user ={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin/banner-trade"
            element={isAdmin ? <AdminbannerTradeAd user ={user} /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin/user"
            element={isAdmin ? <AdminUsers user ={user} /> : <Navigate to="/login" />}
          />
          <Route path="*" element={<NotFound></NotFound>} />
          </Routes>
      </div>
      <div >
      <ConditionalFooter />
      </div>
      
    </Router>
    );
}

export default App;
//