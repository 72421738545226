import React from 'react';
import SearchBox from './Searchbox/Searchbox';
import Alert from 'react-bootstrap/Alert';

export default function Import() {
    return <div class="container-fluid" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
    <Alert className="mt-2">
    You will find all “Import leads” below on assorted basis, but select
category below you wish to view. (All Trade leads/Banner ads are
current from verified users only and visible for 7/10 days only)
Post &amp; access contact details instantly at Zero cost
    </Alert>
    <div className="row text-center">
         <h3>4 free leads a week</h3>
    </div>
    <SearchBox leadType ='import' />
    </div>
}