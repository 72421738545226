import React from 'react';

const Cancelleation = () => {
  return (
    <div class="container mt-5">
      <p style={{ marginTop: 0, marginBottom: 0, lineHeight: 'normal', backgroundColor: '#ebebeb' }}>
        <strong>
          <span style={{ fontFamily: 'Arial', letterSpacing: '-0.3pt', color: '#333333' }}>Cancellation & Refund policy</span>
        </strong>
      </p>
      <p style={{ marginTop: '14pt', marginBottom: '7.5pt', lineHeight: 'normal', fontSize: '11.5pt' }}>
        <span style={{ fontFamily: 'Poppins', color: '#777777' }}>
        Paranji Enterprises will inform/update cancellation and refund policy as and
when we charge nominal fee, since we intent to link traders mutually to grow
potentially.
        </span>
        {/* <span style={{ fontFamily: 'Poppins', color: '#777777' }}>
          &nbsp;&nbsp;
        </span>
        <span style={{ fontFamily: 'Poppins', color: '#777777' }}>
          Banner advertisers/users over cancellation policy and it will be honored if a cancellation request is received to
        </span>
        <span style={{ fontFamily: 'Poppins', color: '#777777' }}>
          &nbsp;
        </span>
        <a href="mailto:info@b2bleads.in" style={{ textDecoration: 'none', color: '#0000ff' }}>
          <u>
            <span style={{ fontFamily: 'Poppins' }}>info@b2bleads.in</span>
          </u>
        </a>
        <span style={{ fontFamily: 'Poppins', color: '#777777' }}>
          &nbsp;
        </span>
        <span style={{ fontFamily: 'Poppins', color: '#777777' }}>
          within 24 hours with an attached invoice copy.&nbsp;
        </span> */}
      </p>
    </div>
  );
};

export default Cancelleation;