import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../../api';
import LoginImage from '../../Images/login.jpg';
import Swal from 'sweetalert2';

function RegistrationForm() {

  const initialFormData = {
    name: '',
    firm_name: '',
    address: '',
    city: '',
    pincode: '',
    state: '',
    country: '',
    email: '',
    mobile: '',
    website: '',
    business_type: '',
    farmers: 'No',
    gst_no: '',
    iec_no: '',
    exim_license: '',
    password: '',
    password_confirmation: '',
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const shouldVerifyGST = () => {
    // Only verify GST for specific business types
    return ['Manufacturer', 'Stockiest', 'Wholesaler','Distributor','Retailer'].includes(formData.business_type);
  };

  const handleGSTVerification = async () => {
    return true; 
    if (shouldVerifyGST()) {
      try {
        const response = await api.post('/verify-gst', { gst_no: formData.gst_no });
        if (!response.data.success) {
          // GST verification failed
          console.error('GST verification failed');
          return false;
        }
      } catch (error) {
        console.error('Error during GST verification:', error);
        return false;
      }
    }
    return true; // GST verification succeeded or not required
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const gstVerificationResult = await handleGSTVerification();

    if (!gstVerificationResult) {
      // GST verification failed, do not submit the form
      Swal.fire({
        icon: 'error',
        title: 'Invalid GST',
        text: 'Please check the GST number and try again.',
      });
      setIsSubmitting(false);
      return;
    }
    try {
      const response = await api.post('/register', formData);
      
        if (response.data.errors) {
          setValidationErrors(response.data.errors);
        } else {
          const { token } = response.data.access_token;
          Cookies.set('authToken', token, { expires: 2 });
          
          setValidationErrors({});
          await Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Registration successful, Email verification link has been sent to your email id, click the link to login to b2bleads',
          });
          navigate('/login');
          
        }

      // Reset the form data
      setFormData({ ...initialFormData });
    } catch (error) {
      setValidationErrors(error.response.data.errors);
      console.error('An error occurred while submitting the data:', error);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center"
    style={{
      backgroundImage: `url(${LoginImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
    }}>
      
      <div className="col-md-5 offset-md-3 border shadow p-5 m-5"
          style={{
            backgroundColor:'white',
            borderRadius:'20px',
          }}>
        <div className='text-center'>
          <h2>Register</h2>
        </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name">Name:*</label>
          <input
            type="text"
            name="name"
            className="form-control"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="firmName">Firm Name:</label>
          <input
            type="text"
            name="firm_name"
            className="form-control"
            value={formData.firm_name}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            name="address"
            className="form-control"
            value={formData.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="city">City:*</label>
          <input
            type="text"
            name="city"
            className="form-control"
            value={formData.city}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="pincode">Pincode:*</label>
          <input
            type="text"
            name="pincode"
            className="form-control"
            value={formData.pincode}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="state">State:*</label>
          <input
            type="text"
            name="state"
            className="form-control"
            value={formData.state}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="country">Country:*</label>
          <input
            type="text"
            name="country"
            className="form-control"
            value={formData.country}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email">E-mail:*</label>
          <input
            type="email"
            name="email"
            className="form-control"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="mobile">Mobile:*</label>
          <input
            type="text"
            name="mobile"
            className="form-control"
            value={formData.mobile}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="website">Website:</label>
          <input
            type="text"
            name="website"
            className="form-control"
            value={formData.website}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="businessType">Type of business:*</label>
          <select
            name="business_type"
            className="form-select"
            value={formData.business_type}
            onChange={handleInputChange}
          >
            <option >Select Business Type</option>
            <option value="Manufacturer">Manufacturer</option>
            <option value="Stockiest">Stockiest</option>
            <option value="Wholesaler">Wholesaler</option>
            <option value="Distributor">Distributor</option>
            <option value="Retailer">Retailer</option>
            <option value="Importer">Importer</option>
            <option value="Exporter">Exporter</option>
            <option value="Individual user">Individual user</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="farmers">Farmers & Agripreneurs:*</label>
          <select
            name="farmers"
            className="form-select"
            value={formData.farmers}
            onChange={handleInputChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        {(formData.farmers === 'No' && formData.business_type !== 'Individual user') && (
        <div className="mb-3">
          <label htmlFor="gstNo">GST No:</label>
          <input
            type="text"
            name="gst_no"
            className="form-control"
            value={formData.gst_no}
            onChange={handleInputChange}
          />
        </div>
        )}
        <div className="mb-3">
          <label htmlFor="iecNo">IEC No:</label>
          <input
            type="text"
            name="iec_no"
            className="form-control"
            value={formData.iec_no}
            onChange={handleInputChange}
          />
        </div>
        {(formData.business_type === 'Importer' || formData.business_type === 'Exporter') && (
        <div className="mb-3">
          <label htmlFor="exim_license">EXIM license/Code/tax/Permit No:</label>
          <input
            type="text"
            name="exim_license"
            className="form-control"
            value={formData.exim_license}
            onChange={handleInputChange}
          />
        </div>
        )}
        <div className="mb-3">
          <label htmlFor="password">Create Password:*</label>
          <input
            type="password"
            name="password"
            className="form-control"
            value={formData.password}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Password_confirmation">Confirm Password:*</label>
          <input
            type="password"
            name="password_confirmation"
            className="form-control"
            value={formData.password_confirmation}
            onChange={handleInputChange}
          />
        </div>
        {Object.keys(validationErrors).length > 0 && (
              <div className="alert alert-danger">
                <ul>
                  {Object.entries(validationErrors).map(([field, errors]) => (
                    <li key={field}>
                      <strong>{field}:</strong> {errors.join(', ')}
                    </li>
                  ))}
                </ul>
              </div>
            )}
        <button type="submit" className="btn btn-primary">
          Register
        </button>
      </form>
      </div>
      {/* <div className="row mt-5 justify-content-center">
      </div> */}
    </div>
  );
}

export default RegistrationForm;
