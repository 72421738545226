import React from 'react';

export default function Terms(){
    return <div class="container">
       <p>
    <strong>Paranji  Enterprises</strong> <strong>Terms of use</strong>
</p>
<p>
    <strong>
        READ  THE TERMS AND CONDITIONS OF THE USER AGREEMENT CAREFULLY
    </strong>
</p>
<p>
    <a name="_GoBack"></a> Welcome  to b2bleads.in - web site of Paranji
    Enterprises. Please read the  Terms and Conditions carefully before using
    this Web site. By using  this site, you agree to be bound by the terms and
    conditions below.  Paranji Enterprises reserves the right to modify these
    Terms and  conditions, the pattern of business mode at any time. Your
    continued  use of b2bleads.in means that you accept changes implemented
    from  time to time. Please do not access any page of this site If you do
    not agree to these terms, contact at
    <u>
        <a href="mailto:info@b2bleads.in">info@b2bleads.in</a>
    </u>
</p>
<p>
    <strong>Paranji  Enterprises</strong> acts as a Network platform between
    different segments of the business  community among Manufacturers,
    suppliers, wholesalers, Exporters &amp;  Importers &amp; Distributors.
</p>
<p>
    <strong>Paranji  Enterprises</strong> has no involvement in any financial
    transaction between buyer and  seller. We have no control over the quality,
    safety, or legality of  items advertised the truth or accuracy of the
    listings, the ability  of sellers to sell items they list, or the ability of
    buyers to buy  items.
</p>
<p>
    Always  remember that there are risks in dealing with people whom you don’t
    know and that Paranji Enterprises has no control over such risks.  Users are
    suggested to act according to their business acumen with  their judgment.
    Users are advised and encouraged to take due  precautions while dealing with
    other users of the website.
</p>
<p>
    <strong>Paranji  Enterprises</strong> is not involved in any transactions
    between you and other users  either directly or indirectly and it is the
    responsibility of the  user to release Paranji Enterprises and our agents,
    employees, and  affiliates from all refunds or warranties, claims, demands,
    and  damages (actual and consequential) of every kind and nature, known  and
    unknown, suspected and unsuspected, disclosed and undisclosed,  arising out
    of or in any way connected with listings, completed or  incomplete
    transactions, or items offered or sold.
</p>
<p>
    You  must be at least 18 years of age and possess the legal authority to
    enter into this agreement and to use the services by all terms and
    conditions herein. By agreeing to use b2bleads.in services or
    registering as a free/paid subscriber - you certify that you are 18  years
    of age or older and a legitimate agent for the party for whom  you claim to
    represent. To register, you must provide your real name  and e-mail,
    address, street address, telephone number, and if you are  selling goods,
    all information about your listed goods is true and  accurate. You warrant
    that you will abide by all such additional  procedures and guidelines, as
    modified from time to time, in  connection with your use of the services.
    You further warrant that  you will comply with all applicable laws and
    regulations regarding  the use of the services without limitation, by
    listing an item for  sale, you warrant that you have the legal right to sell
    the item.
</p>
<p>
    <strong>Paranji  Enterprises</strong>
    <strong>
        (Listing  and non-listing leads/post)
    </strong>
</p>
<p>
    It  is the user's responsibility to determine whether the listed leads/
    banner ads feed are authorized by law, statute, or regulation without
    limitation. The Trade lead/Ads feed pertaining to the following  category of
    product/content will not be posted on the b2bleads.in.  i.e. namely
</p>
<p>
    Firearms,  explosives and ammunition, narcotic drugs, sex toys, counterfeit,
    pirated or stolen goods, any goods that infringe or otherwise violate
    another party rights, including copyright, patent, trademark, and  rights of
    publicity and privacy; indecent, obscene, or pornographic;  registered or
    unregistered securities; objectionable and hatred  content, goods which you
    do not have the legal right to sell goods  which are misrepresented or which
    do not, in fact, exist, etc. Each  lead will be checked manually before
    activating and the wrongdoer's  account will be deleted, without notice.
</p>
<p>
    <strong>Paranji  Enterprises reserves</strong> the  right to cancel any
    listing at any time and reserves the right to  prohibit or restrict access
    to the services to anyone at any time,  with or without cause. Paranji
    Enterprises reserves the right of  removing the profiles of people who
    register with bogus addresses,  telephone, and fax information.
</p>
<p>
    Visitor/Subscriber/Member  shall be fully responsible and liable for breach
    of these terms and  conditions or infringement of the copyright vesting in
    the website  and such liability shall not be limited on any grounds or
    reason  whatsoever. They agree to indemnify Paranji Enterprises and its
    associates and employees against any and all losses, claims, demands,
    expenses (including attorney's costs and fees), and/or any other  liability
    of whatsoever nature or kind including third-party  liabilities arising out
    of the breach of these terms and conditions  of infringement of the
    copyright vesting in the Database.
</p>
<p>
    These  terms and conditions create an agreement that is effective unless
    terminated by either party, you or Paranji Enterprises. Paranji  Enterprises
    shall cancel without any refund, membership/subscriptions   if any, which
    are found breaching the conditions set above, and  appropriate action will
    be initiated.
</p>
<p>
    These  terms are governed by applicable law in the state of Tamil Nadu,
    India. You agree that any action at law arising out or relating to  these
    terms shall be filed only in courts located in Chennai, India  and you
    hereby consent and submit to the personal jurisdiction of  such courts for
    the purposes of litigating any such action.
</p>
<p>
    Members/visitors/subscribers  hereby agree not to communicate objectionable
    materials through a  letter, e-mail, posting in Bulletin Boards, or any
    other form as  determined by Paranji Enterprises. Examples of objectionable
    materials include but are not limited to:
</p>
<ul>
    <li>
        <p>
            Adult/sex-related          content or link to such sites
        </p>
    </li>
    <li>
        <p>
            Referencing          of sexually explicit images or other offensive
            content
        </p>
    </li>
    <li>
        <p>
            Promotion          of adult services, such as phone sex or escort
            services
        </p>
    </li>
    <li>
        <p>
            The          content of website, newsletter, or e-mail cannot
            infringe on any          personal, intellectual property, or
            copyrights
        </p>
    </li>
    <li>
        <p>
            Content          relating to Racial, ethnic, political,
            hate-mongering, or otherwise          objectionable content
        </p>
    </li>
    <li>
        <p>
            Fraudulent          investment, money-making opportunities, or
            advice not permitted          under law
        </p>
    </li>
    <li>
        <p>
            Gratuitous          violence or profanity material that defames,
            abuses, or threatens          physical harm to others or to you.
            Promotion of illegal substances          or activities such as
            illegal online gambling, explosives, Ponzi          scheme,
            counterfeiting money, etc.
        </p>
    </li>
    <li>
        <p>
            Explicit,          vulgar, or obscene language posting at website.
        </p>
    </li>
</ul>
<ul>
    <li>
        <p>
            Paranji          Enterprises disclaims any responsibility for the
            accuracy of the          content of the Trade leads/ Banner Ads
            feeds. Visitors assume all          the risk of viewing, reading,
            using, or relying upon this          information. Visitors have no
            right to rely on any information          contained herein as
            accurate. The website makes no such warranty.
        </p>
    </li>
</ul>
<ul>
    <li>
        <p>
            Paranji          Enterprises activates each and every post manually
            after review.          Paranji Enterprises admin reserve the right
            to publish at its sole          discretion based on authorized
            content only. Member/subscriber can’t          claim the right to
            get the information published on this website.
        </p>
    </li>
</ul>
<ul>
    <li>
        <p>
            Paranji          Enterprises has no obligation to monitor the
            information, products,          and/or materials posted on the
            Website of the Company although the          Company shall at its
            sole discretion and without assigning any          reason whatsoever
            shall have the right to remove or edit any content          or
            information that in its sole discretion. Notwithstanding this
            right, the advertiser remains solely responsible for the content,
            information, product, service, or materials uploaded on the Website
            of the Company.
        </p>
    </li>
</ul>
<ul>
    <li>
        <p>
            We          provide support service over feedback/suggestion/any
            query over          email only.
        </p>
    </li>
</ul>
<p>
    Paranji  Enterprises asks for basic and minimum information such as Name,
    Firm  Name, e-mail, address, website, Mobile No, city, and GST No for
    verification.
</p>
<p>
    GST/IEC  holder (within India) &amp; an accredited Importers from overseas
    only  can post their            Trade  leads
</p>
<p>
    Paranji  Enterprises never asks for any other personal information like
    Aadhar  card, bank account details, password, credit or debit card
    information, physical or mental health conditions, medical records,
    bio-metric information, etc. The Company does not collect any  Sensitive
    Personal Data/Information of a User.
</p>
<p>
    <strong>Other  Terms and Conditions:</strong>
</p>
<p>
    <strong>
        Member/subscriber/visitor  irrevocably promises and agrees to hold
        b2bleads.in harmless from  and against any/ all losses, claims,
        expenses, suits, damages, costs,  demands, or liabilities, arising from
        any/all claims and lawsuits  since our mission is to serve the Traders
        community to grow more on a  win-win basis on simple and user-friendly
        means .
    </strong>
</p>
<p>
    If  any member/subscriber/visitor violates or refuses to take part in  their
    responsibilities, or commits fraudulent activity, Paranji  Enterprises
    reserves the right to terminate subscription and take  appropriate legal
    action to cover its damages.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> reserves  the right to
    change any condition or conditions of this contract,  membership services,
    membership fee, or any other related terms,  conditions, or policy at any
    time. Changes will be displayed on the  website, no other communication will
    be sent to  members/subscribers/visitors.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> may  modify this
    Agreement at any time without notice to Paranji  Enterprises users/members.
    This agreement was last modified on 01 Oct  2023  by our legal advisor.
</p>
<p>
    Member/subscriber/visitor  agrees to settle any dispute under this agreement
    under the laws of  Chennai, India. Any litigation arising under this
    Agreement will be  brought in the courts of Tamil Nadu state.
</p>
<p>
    If  any provision of this agreement shall be held or made invalid or
    unenforceable by a court decision, statute, rule, or otherwise, the
    remaining provisions of this agreement shall not be affected thereby.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong>
    <strong>
        Relationship  of the Parties
    </strong>
</p>
<p>
    It  is clarified that there is no agency or/and partnership or/and joint
    venture or/and employee-employer or/and franchiser-franchisee  relationship
    between the Company and any User.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong>
    <strong>
        User  admits and acknowledges that:-
    </strong>
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> cannot take
    responsibility or control the information provided by the  Users, which is
    made available on the Website of the Company as the  Company has provided
    information, display of the products of the User  on its Website in good
    faith and solely based on representations of  the User, which the Company
    has no reason to disbelieve.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> shall be solely and
    exclusively responsible in respect of all or any  liability arising out of
    any sale of any product/services and/or  consequent to any display of any
    product or its description and  features as the Company has only provided
    the link to the User and  nothing more.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> does not facilitate
    the purchase of pharmaceutical products, and only  advertises and/or
    showcases the pharmaceutical products posted by  Users(s). User(s) involved
    in the purchase and supply of  pharmaceutical products hereby agree to abide
    by and be compliant  with any applicable laws, rules, regulations,
    notifications, or  orders issued by the Government of India or any of its
    agencies from  time to time in this regard including but not limited to
    Drugs and  Cosmetics Act, 1940, Drugs and Cosmetics Rules, 1945 ("Drug
    Rules"), Drugs and Magic Remedies (Objectionable Advertisements)  Act, 1954
    ("Drug and Magic Remedies Act").
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> does not offer any
    guarantees or warranties on the medicinal products  or services displayed or
    listed on the Web Site and is not liable for  any relevant transaction
    between the User(s), including transactions  involving the sale of any
    medicine(s) restricted and/or banned for  sale by any governmental or any
    other regulatory authorities.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> in no way may be held
    liable for User-provided information that may  turn out to be offensive,
    harmful, inconsistent, inaccurate, or  deceptive.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> is only giving the
    uploading rights to the User to upload user   product or service and its
    contents on the Website and that the  company shall not be responsible or
    liable for the quality, quantity,  description, content, and/or other
    information in respect of the  product and/or service.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> is not responsible for
    any non-performance or breach of any contract  entered into between Users.
    The company cannot and does not guarantee  that the concerned users will
    perform any transaction concluded on  the Site. The company shall not and is
    not required to mediate or  resolve any dispute or disagreement between
    Users.
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong> site is also a channel
    of communication whereby the users can reach a  large Network of
    Manufacturers, Wholesalers, Distributors, service  providers in India and as
    well as Exporters and Importers in the  global market. The Company is only
    providing a platform for  communication and it is agreed that the contract
    for the sale of any  of the products or services shall be a strictly
    bipartite contract  between the seller and the buyer, respectively. At no
    time shall the  Company have any obligations or liabilities in respect of
    any such  contract over delivery schedule, damages, rejection, payment
    disputes, etc.
</p>
<p>
    Users  are suggested to be more cautious, use common sense, and practice
    with business acumen for safe trading when using the Site. Please  also note
    that there are risks of dealing with unknown persons,  underage persons, or
    people acting under pretenses.
</p>
<p>
    We  generally give an option to upload copy of license/registration
    code/Tax NO (copy), various certificates etc as applicable to  overseas
    countries for Exporters and Importers and as well as to  Traders community
    in India (GST) while registering, to tag them as  Verified Traders by which
    user to know traders  authenticity/credibility to join and grow more
    silently.
</p>
<p>
    <strong>LINKS  TO THIRD-PARTY SITES:</strong>
</p>
<p>
    Paranji  Enterprises gives a direct link to the website to the
    Advertisements  published thru Advertise column only. Users will get the
    details of  website /Catalogue links directly when they click
    share/send/save/contact advertiser option for the Banner Ads posted  in
    <strong>Trade  leads/Ads Banner.</strong>
</p>
<p>
    <strong>Paranji</strong> <strong>Enterprises</strong>
    <strong>
        -Trademark
    </strong>
</p>
<p>
    Paranji  Enterprises and related icons and logos are registered trademarks
    or  trademarks or service marks of b2bleads.in various jurisdictions  and
    are protected under applicable copyright, trademark, and other  proprietary
    and intellectual property rights laws. The unauthorized  adoption copying,
    modification, use, or publication of these marks is  strictly prohibited.
</p>
<p>
    <strong>Grievance/Dispute  redressal:</strong>
</p>
<p>
    For  any complaints and grievances, the users may contact customer care
    thru e-mail to <u><a href="mailto:info@b2bleads.in">info@b2bleads.in</a></u>
    The executive in charge will redress all complaints within 10 days  from the
    date of receipt of a complaint.
</p>
<p>
    <strong>Arbitration  Clause for Dispute</strong>
</p>
<p>
    Any  dispute or difference of any nature whatsoever arising out of or  about
    this Agreement including in respect of the indemnity clause of  this
    Agreement shall be finally decided following the provisions of  the
    Arbitration &amp; Conciliation Act, 1996 or any other applicable  law at the
    relevant point of time. The language of the arbitration  shall be in English
    and the venue of arbitration shall be in Chennai,  India.
</p>
<p>
    <strong>Governing  Law and Jurisdiction</strong>
</p>
<p>
    This  Agreement and any dispute or matter arising out of or in connection
    with and/or incidental to the use of the Site and/or the Services  shall be
    governed by the laws of India without regard to its conflict  of law’s
    provisions. The User and the Company hereby irrevocably  submit to the sole
    and exclusive jurisdiction of the courts at  Chennai, India.
</p>
<p>
    <strong>
        This  document is an electronic record in terms of the Information
        Technology Act, 2000 and the provisions about electronic records in
        various statues amended by the Information Technology Act, 2000.
    </strong>
</p>
<p>
    <strong>Disclaimer:</strong> We  are in no way responsible for any financial
    transactions and suggest  utilizing the leads/ads with your business acumen
    approach. You can  interact directly as you are the best judge of your
    trade. All rights  reserved®.
</p>
    </div>
}