import React, { useState } from 'react';

const UserEditForm = ({ user, onSave, onCancel }) => {
  const [editedUser, setEditedUser] = useState({ ...user });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    // Perform any additional validation or checks here if needed
    onSave(editedUser);
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={editedUser.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={editedUser.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile</label>
          <input
            type="text"
            className="form-control"
            id="mobile"
            name="mobile"
            value={editedUser.mobile}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
            value={editedUser.address}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input
            type="text"
            className="form-control"
            id="city"
            name="city"
            value={editedUser.city}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="pincode">Pincode</label>
          <input
            type="text"
            className="form-control"
            id="pincode"
            name="pincode"
            value={editedUser.pincode}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country</label>
          <input
            type="text"
            className="form-control"
            id="country"
            name="country"
            value={editedUser.country}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="website">Website</label>
          <input
            type="text"
            className="form-control"
            id="website"
            name="website"
            value={editedUser.website}
            onChange={handleChange}
          />
        </div>
        <label htmlFor="businessType">Type of Business</label>
        <select
            className="form-control"
            id="businessType"
            name="business_type"
            value={editedUser.business_type}
            onChange={handleChange}
        >
            <option value="Manufacturer">Manufacturer</option>
            <option value="Stockiest">Stockiest</option>
            <option value="Wholesaler">Wholesaler</option>
            <option value="Existing Distributor">Existing Distributor</option>
            <option value="Retailer">Retailer</option>
            <option value="Importer">Importer</option>
            <option value="Exporter">Exporter</option>
            <option value="Individual user">Individual user</option>
        </select>
        <div className="form-group">
          <label htmlFor="gstNo">GST No.</label>
          <input
            type="text"
            className="form-control"
            id="gstNo"
            name="gst_no"
            value={editedUser.gst_no}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="iecNo">IEC No.</label>
          <input
            type="text"
            className="form-control"
            id="iecNo"
            name="iec_no"
            value={editedUser.iec_no}
            onChange={handleChange}
          />
        </div>
        {/* Add more fields as needed */}
        <button type="button" className="btn btn-primary  p-2 m-2" onClick={handleSaveClick}>
          Save
        </button>
        <button type="button" className="btn btn-secondary p-2" onClick={onCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default UserEditForm;
