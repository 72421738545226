import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Catalog1 from '../Images/Catalog.jpg';
import Catalog2 from '../Images/Catalog2.jpg';
import Catalog3 from '../Images/Catalog3.jpg';
import Catalog4 from '../Images/Catalog4.jpg';
import Catalog5 from '../Images/Catalog4.jpg';
import TradeIndia from '../Images/traderslinks.jpg';
import './HomePageContent.css';

function HomePageContent() {
  return (
    <Container className='mt-2'>
      <Row>
      <div className='col-md-6 col-lg-6'>
            <img  className='border p-5 img-fluid' src={TradeIndia} alt="Trade" height='500' width='600' />
      </div>
      <div className='col-md-6 col-lg-6 p-3 d-flex align-items-center'>
        
        <div style={{  textAlign: 'justify' }}>
        <div className='text-center'>
        <h2>Jo dikhta hai woh bikta hai!</h2>
        </div>
          <h5 className='mb-3'>
          www.b2bleads.in is a simple B2B portal (digital advertising) linking traders, focusing day-to-day needs of trade leads, Export & import leads in 100+ categories with free access to all.
          </h5>
          <h5 className='mb-3'>
          Entrepreneurs and service providers can display their banner ads for introduction/promotion of MSME, start-up, brand, inauguration, events and exhibition, consulting service, transport and cargo, finance and loan,lab testing, business sales, etc. related to B2B in 300+ Service categories.  
          </h5>
          
        </div>

      {/* <p>Your one-stop market intelligence platform dedicated to traders across Pan India, as well as exporters and importers across the globe.</p>
      <p>Trade leads from verified traders (GST/IEC holders/farmers) are only post on a day-to-day need basis with free access to Traders on “Zero cost“ to explore potential opportunities on a win-win basis to grow your business.</p>
      <p>We believe in transparency, user-friendly, economic, and simplicity.</p>
      <p>We are unique since it is meant for B2B purposes only, as traders are their own masters with acumen in their field of business..</p> */}
      </div>
      </Row>
      {/* <Row className="mt-3">
    <section className="story">
        <h3>Our Story</h3>
        <p>B2B Trade Leads was established in 2023 with a vision and mission led by an Ex-Veteran from the Indian Air Force. Our aim is to revolutionize the way B2B businesses operate in the AI era (Artificial Intelligence) by simplifying with the TUES CONCEPT - Transparency, User-friendly, Economic, and Simplicity.</p>
        <h3>What We Do at B2B Trade Lead</h3>
        <ul>
            <li>Users can post eye-catching Ad banners on trade leads/services with full contact details at nominal charges, following the principle of "What you see is what sells."</li>
            <li>We cover over 70+ categories, encompassing thousands of products.</li>
            <li>All trade leads are meant for B2B purposes only, not for individual use.</li>
            <li>We don't engage in unsolicited calls, SMS, or WhatsApp messages. That's our commitment to you.</li>
        </ul>
    </section>       
      </Row> */}
    <Carousel>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={Catalog1}
          alt="Catalog"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={Catalog2}
          alt="Catalog"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={Catalog3}
          alt="Catalog"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={Catalog4}
          alt="Catalog"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={Catalog5}
          alt="Catalog"
        />
      </Carousel.Item>
    </Carousel>
    </Container>
  );
}

export default HomePageContent;
