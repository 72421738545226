import React from 'react';
import { Navbar, Nav, NavLink } from 'react-bootstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import LogoImage from '../../Images/logo.jpg';
import './NavBar.css';
import { useAuth } from '../../Context/AuthContext';
import CenterImage from '../../Images/centerTop.jpg';

function NavBar({isAuthenticated}) {
  const { user } = useAuth();
  return (
    <div>
      <Navbar  variant="light" expand="lg" className='p-1'>
      <Navbar.Brand>
        <img src={LogoImage} alt="Logo" height="50"/>
        </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to="/">Home</NavLink>
          <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to="/advertise">Advertise</NavLink> {/* Use Link component here */}
          {/* <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to="/pricing">Pricing</NavLink>  */}
          <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to="/banner-ads">Banner Ads Gallery</NavLink> {/* Use Link component here */} {/* Use Link component here */}
          <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to="/contact">Contact</NavLink> {/* Use Link component here */}
          
        </Nav>
        
        <Nav>
        <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to='/postlead'>Post Trade Leads <span className='danger'>(Free)</span></NavLink>
        {  !isAuthenticated ?
            (<>
              <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to='/login'>Login</NavLink>
              <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to='/register'>Register</NavLink>
            </>)
          :(
            <>
            <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to='/user'>{user.name}</NavLink>
            <NavLink className='nav-link' as={RouterNavLink} activeClassName="active" to='/logout' >Logout</NavLink>
            </>
          )}
            
          
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <div style={{backgroundColor : "#061993", color:'whitesmoke'}} className='row text-center'>
      <div className="col-md-12">
      <h4>आप जो देखते हैं वही बिकता है ! - What you see is what sells</h4>
      {/* <img src={CenterImage} alt="Logo"  height="100"/> */}
      </div>
    </div>
    </div>

    
  );
}

export default NavBar;