import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Sidebar from './Sidebar'; 
import BannerAdTradeList from './BannerAdTradeList';
import BannerAdTradeForm from './BannerAdTradeForm'; // Import your BannerAdForm component

function AdminbannerTradeAd(user) {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)', backgroundColor:'white' }}>
      <Sidebar />
      <div className="content">
        <div className="row justify-content-end m-3">
          <div className="col-md-3">
          <Button variant="primary" onClick={handleOpenModal}>
            Add Banner Trade Ad
          </Button>
          </div>
          
        </div>

        {/* Your existing BannerAdTradeList component */}
        <BannerAdTradeList />

        {/* Bootstrap Modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Banner Ad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Your BannerAdForm component goes here */}
            <BannerAdTradeForm onClose={handleCloseModal} user={user} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default AdminbannerTradeAd;
