import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import api from '../api';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    //const { token: urlToken, email: urlEmail } = useParams();
    const getQueryParam = (name) => new URLSearchParams(location.search).get(name);

    useEffect(() => {
        // Set the email and token from the URL parameters
        const token = getQueryParam('token');
        const email = getQueryParam('email');

        console.log('Token:', token);
        console.log('Email:', email);
      }, [location.search]);

      const handleResetPassword = async (e) => {
        e.preventDefault();
    
        try {
          // Make an API call to reset the password
          const response = await api.post('/password/reset', {
            email : getQueryParam('email'),
            password,
            password_confirmation,
            token: getQueryParam('token'),
          });
    
          // Check the response for success or error messages
          console.log(response.data);
    
          // After successful password reset, redirect to login page
          navigate('/login');
        } catch (error) {
          // Handle error, e.g., display an error message to the user
          console.error('Error resetting password:', error);
        }
      };

  return (
    <div className="container mt-5">
      <div className="card mx-auto" style={{ maxWidth: '400px' }}>
        <div className="card-body">
          <h4 className="text-center">Reset Password</h4>
          <form onSubmit={handleResetPassword}>
            <div className="form-group mb-1">
              <label htmlFor="password">New Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group mb-1">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                placeholder="Confirm new password"
                value={password_confirmation}
                onChange={(e) => setPassword_confirmation(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
