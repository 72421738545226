import React, { useState, useEffect } from 'react';
import api from '../../api';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import './Searchbox.css';
import { ImageUrl } from '../../global';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import moment from"moment";
import Swal from 'sweetalert2';

function SearchBox({leadType}) {
  const [selectedLeadType, setSelectedLeadType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productQuery, setProductQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isPending, setIsPending] = useState(true); 
  const [selectedLead, setSelectedLead] = useState(null);
  const [banner_ads, setBanner_ads] = useState([]);
  const [banner_adstr, setBanner_adstr] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const leadTypeOptions = [
    { value: 'buy', label: 'Buy lead' },
    { value: 'sell', label: 'Sell lead' },
    { value: 'distributorship', label: 'Distributorship available'},
    { value: 'export', label:'Export lead'},
    { value: 'import', label: 'Import lead' },
    { value: 'distwanted', label:'Distributorship wanted'}
  ];
  
  const categoryOptions = [
    { value: 'Agri & Food Processing Machinery & Equipment', label: 'Agri & Food Processing Machinery & Equipment' },
    { value: 'Alcoholic Beverages, Tobacco & Related Products', label: 'Alcoholic Beverages, Tobacco & Related Products' },
    { value: 'Ayurvedic & Herbal products', label: 'Ayurvedic & Herbal products' },
    { value: 'Baby products', label: 'Baby products' },
    { value: 'Bags, Baggages & Suitcases', label: 'Bags, Baggages & Suitcases' },
    { value: 'Bar Accessories and Related Products', label: 'Bar Accessories and Related Products' },
    { value: 'Books, Magazines, Music & Entertainment', label: 'Books, Magazines, Music & Entertainment' },
    { value: 'Builders Hardware, Construction Material & Equipment', label: 'Builders Hardware, Construction Material & Equipment' },
    { value: 'Carpets, Mats, Rugs, Floorings', label: 'Carpets, Mats, Rugs, Floorings' },
    { value: 'Chemicals & Allied Products', label: 'Chemicals & Allied Products' },
    { value: 'Computer Software & Hardware', label: 'Computer Software & Hardware' },
    { value: 'Cosmetics, Toiletries Hygiene & Personal care Products', label: 'Cosmetics, Toiletries Hygiene & Personal care Products' },
    { value: 'Electrical Household & Other Goods, Components', label: 'Electrical Household & Other Goods, Components' },
    { value: 'Electronics & Communications', label: 'Electronics & Communications' },
    { value: 'Elevators & Escalators', label: 'Elevators & Escalators' },
    { value: 'Environment', label: 'Environment' },
    { value: 'Farm Inputs - Fertilizers, Pesticides & Seeds', label: 'Farm Inputs - Fertilizers, Pesticides & Seeds' },
    { value: 'Farm Products - Grains, Fruits Spices, etc', label: 'Farm Products - Grains, Fruits Spices, etc' },
    { value: 'Food and beverages', label: 'Food and beverages' },
    { value: 'Fish & Fish Products', label: 'Fish & Fish Products' },
    { value: 'Fitness Equipment & Sports Goods', label: 'Fitness Equipment & Sports Goods' },
    { value: 'Fuel & Energy Resources', label: 'Fuel & Energy Resources' },
    { value: 'Furniture, Interior Decoration & Furnishings', label: 'Furniture, Interior Decoration & Furnishings' },
    { value: 'Gems & Jewellery', label: 'Gems & Jewellery' },
    { value: 'Glass & Ceramics', label: 'Glass & Ceramics' },
    { value: 'Handicrafts, Gifts & Decorative', label: 'Handicrafts, Gifts & Decorative' },
    { value: 'Heating, Air Conditioner & Ventilation', label: 'Heating, Air Conditioner & Ventilation' },
    { value: 'Health and beauty', label: 'Health and beauty' },
    { value: 'Horticulture - Flowers, Plants & Related Products', label: 'Horticulture - Flowers, Plants & Related Products' },
    { value: 'Hotel & Restaurant - Equipments & Products', label: 'Hotel & Restaurant - Equipments & Products' },
    { value: 'Household & General Merchandise', label: 'Household & General Merchandise' },
    { value: 'Home Furnishings', label: 'Home Furnishings' },
    { value: 'Industrial Goods & Supplies', label: 'Industrial Goods & Supplies' },
    { value: 'Industrial Machinery & Plants & Supplies', label: 'Industrial Machinery & Plants & Supplies' },
    { value: 'Information Databases', label: 'Information Databases' },
    { value: 'Jute & Coir - Raw Material, Processed & Related Machinery', label: 'Jute & Coir - Raw Material, Processed & Related Machinery' },
    { value: 'Kitchen Utensils & Appliances', label: 'Kitchen Utensils & Appliances' },
    { value: 'Lab Instruments & Suppliers', label: 'Lab Instruments & Suppliers' },
    { value: 'Leather & Leather Products', label: 'Leather & Leather Products' },
    { value: 'Lights & Lighting', label: 'Lights & Lighting' },
    { value: 'Livestock, Poultry, Meat & Animal Products', label: 'Livestock, Poultry, Meat & Animal Products' },
    { value: 'Marine Equipment & Machinery', label: 'Marine Equipment & Machinery' },
    { value: 'Medical , HealthCare & Surgical', label: 'Medical , HealthCare & Surgical' },
    { value: 'Mineral & Metal Products', label: 'Mineral & Metal Products' },
    { value: 'Metallic Elements', label: 'Metallic Elements' },
    { value: 'Non Metallic Elements', label: 'Non Metallic Elements' },
    { value: 'Office Equipment, Stationery & Supplies', label: 'Office Equipment, Stationery & Supplies' },
    { value: 'Ores & Minerals', label: 'Ores & Minerals' },
    { value: 'Paper & Paper Products', label: 'Paper & Paper Products' },
    { value: 'Packaging products', label: 'Packaging products' },
    { value: 'Petroleum, Oil & Related Products', label: 'Petroleum, Oil & Related Products' },
    { value: 'Pets & Pet Supplies', label: 'Pets & Pet Supplies' },
    { value: 'Photographic & Optical Equipment', label: 'Photographic & Optical Equipment' },
    { value: 'Plastic Products', label: 'Plastic Products' },
    { value: 'Pollution Control Equipments', label: 'Pollution Control Equipments' },
    { value: 'Power Plants', label: 'Power Plants' },
    { value: 'Printing & Display Products', label: 'Printing & Display Products' },
    { value: 'Processed Food & Beverages Products & By Products', label: 'Processed Food & Beverages Products & By Products' },
    { value: 'Refrigeration, Cold Storage', label: 'Refrigeration, Cold Storage' },
    { value: 'Rubber & Related Items', label: 'Rubber & Related Items' },
    { value: 'Safety & Security Equipment', label: 'Safety & Security Equipment' },
    { value: 'Shipping Service', label: 'Shipping Service' },
    { value: 'Sports Goods and Entertainment', label: 'Sports Goods and Entertainment' },
    { value: 'Telecommunications', label: 'Telecommunications' },
    { value: 'Textiles, Garment, Fashion Accessories & Footwear', label: 'Textiles, Garment, Fashion Accessories & Footwear' },
    { value: 'Tools & Instruments', label: 'Tools & Instruments' },
    { value: 'Toys & Fancy products', label: 'Toys & Fancy products' },
    { value: 'Transportation - Air, Rail, Auto Components', label: 'Transportation - Air, Rail, Auto Components' },
    { value: 'Watches & Clocks', label: 'Watches & Clocks' },
    { value: 'Wood - Raw Material, Processed & Related Machinery', label: 'Wood - Raw Material, Processed & Related Machinery' },
  ];
  const renderBannerCarousel = () => (
    <Carousel>
      {banner_ads.map((banner_ad) => (
        <Carousel.Item key={banner_ad.id}>
          <img
            className="d-block w-100"
            src={`${ImageUrl}/storage/tradeAds/${banner_ad.image_path}`}
            alt="Banner Ad"
            height="150"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );


  // Function to render the leads and banner ads in carousels
  const renderLeadsAndBanners = () => {
    const result = [];
    let leadsCount = 0;

    // Iterate through search results
    searchResults.forEach((lead, index) => {
      if (leadsCount % 4 === 0) {
        // Display carousel after every 4 leads
        result.push(
          <Row >
            <Col md={12} key={`carousel-${leadsCount}`} className="mb-5">
            {renderBannerCarousel()}
            </Col>
          </Row>
          
        );
      }

      // Render the lead
      result.push(
          <Row className='mb-5 justify-content-md-center' key={lead.id}>
            <Card style={{ width: '700px' }}>
            <Card.Header>
            Date: {lead.posted_date}&emsp;Name:{lead.lead_title} &emsp; {lead.lead_type === 'import' || lead.lead_type === 'export' ? (<>Country:{lead.lead_location}</>) : (<>City:{lead.lead_location}</>)}
            </Card.Header>
            <Card.Body>
              <Card.Title></Card.Title>
              <Row className='mb-2'>
                <Col md={2}>
                <Card.Img variant="left" src={`${ImageUrl}/storage/${lead.image_path}`} 
                width ="100" 
                height ="100" 
                onClick={() => handleImageClick(lead.image_path)}
                />
                </Col>
                <Col>
                 Description :{lead.lead_description}
                </Col>
              </Row>
              <Row className='mb-2'>
              <Card.Text>
              Type of business:&nbsp;{leadTypeOptions.find(option => option.value === lead.lead_type)?.label}   &emsp;Category:&nbsp; {lead.lead_category}&emsp;Product name:{lead.product_name}
              </Card.Text>
              </Row>
              
              <Button variant="primary" className='ml-5' onClick={() => handleGetContact(lead)}>Get Contact</Button>
            </Card.Body>
            </Card>
        </Row>
      );

      leadsCount += 1;

      // Display the remaining leads if any
      if (index === searchResults.length - 1 && leadsCount > 0) {
        result.push(
            <Col md={12} key={`carousel-${leadsCount}`} className="mb-5">
            {renderBannerCarousel()}
            </Col>
        );
      }
    });

    return result;
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(`${ImageUrl}/storage/${imagePath}`);
  };

  const handleTradeImageClick = (imagePath) => {
    setSelectedImage(`${ImageUrl}/storage/tradeAds/${imagePath}`);
  };

  // Function to close the full-screen image modal
  const handleCloseImageModal = () => {
    setSelectedImage(null);
  };


  const fetchBannerAds = () =>{
    api.get("/bannerads?filter[status]=Active")
    .then((response) => {
      const currentDate = moment();
          const filteredBannerAds = response.data.data.filter((bannerAd) => {
          const expirationDate = moment(bannerAd.expiration_date, 'YYYY-MM-DD HH:mm:ss');
          return expirationDate.isSameOrAfter(currentDate, 'day');
        });
        setBanner_ads(filteredBannerAds);
      })
      .catch((error) => {
        console.error('Error fetching ads:', error);
      });
}


const fetchBannerTradeAds = () =>{
  let leadTypeToUse = leadType;
  console.log(leadTypeToUse);
  if(leadTypeToUse === 'nill'){
    leadTypeToUse = (selectedLeadType && selectedLeadType.value);
    console.log(leadTypeToUse);
  }
    api.get(`/banneradtrades?filter[trade_lead_category]=${leadTypeToUse}&filter[status]=Active`)
    .then((response) => {
        const currentDate = moment();
        const filteredBannerTradeAds = response.data.data.filter((bannerTrdeAd) => {
          const expirationDate = moment(bannerTrdeAd.expiration_date, 'YYYY-MM-DD HH:mm:ss');
          return expirationDate.isSameOrAfter(currentDate, 'day');
        });
        setBanner_adstr(filteredBannerTradeAds);
      })
      .catch((error) => {
        console.error('Error fetching ads:', error);
      });
}

  const handleLeadTypeChange = (selected) => {
    setSelectedLeadType(selected);
  };

  const handleCategoryChange = (selected) => {
    setSelectedCategory(selected);
  };

  const handleProductInputChange = (e) => {
    setProductQuery(e.target.value);
  };

  const handleSearch =  async () => {
    try {
        const response = await api.get('/leads', { params: {
          'filter[lead_type]': selectedLeadType ? selectedLeadType.value : leadType,
          'filter[lead_category]': selectedCategory ? selectedCategory.value : '',
          'filter[product_name]': productQuery,
          'filter[status]':'Active',
        }, });
        
        const currentDate = moment();
        const filteredLeads = response.data.data.filter((lead) => {
          const expirationDate = moment(lead.expiration_date, 'YYYY-MM-DD HH:mm:ss');
          return expirationDate.isSameOrAfter(currentDate, 'day');
        });

        setIsPending(false);
        setSearchResults(filteredLeads);
        fetchBannerAds();
        console.log(banner_adstr);
        fetchBannerTradeAds();
    } catch (error) {
        console.error(error);
    }

  };


  const handleGetContact = async (lead) => {
    setSelectedLead(lead);
    const isAuthenticated = () => {
      const authToken = Cookies.get('authToken');
      return !!authToken;
    };
    if (isAuthenticated()) {
        const response = await api.post('/sendLead-email', { lead: lead });
        if(response.data.message === "Lead limit per week is over"){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Lead limit per week is over',
          });
        
        } else{
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Poster contact details sent to your Email id / Your contact details are sent to poster',
          });
         
        }  
    } else{
      toast.error('Login to get contact!', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      navigate('/login');

    }
    
  };

 const bannerHeading = () =>{
  console.log('test', leadType);
  if (leadType === 'buy'){
    return <div className='text-center'>Buy Lead</div>;
  }
  else if (leadType === 'sell')
  {
    return <div className='text-center'>Sell Lead</div>;
  }
  else if (leadType === 'import')
  {
    return <div className='text-center'>Import Lead</div>;
  }
  else if (leadType === 'export')
  {
    return <div className='text-center'>Export Lead</div>;
  }
  else if (leadType === 'distributorship')
  {
    return <div className='text-center'>Distributorship Available Lead</div>;
  }
  else if(leadType === 'distwanted')
  {
    return <div className='text-center'>Distributorship Wanted Lead</div>;
  }
  
 }
  useEffect(() => {
    handleSearch();
    fetchBannerTradeAds(); 
    fetchBannerAds();
  }, []);


  return (
    <Container fluid className = "mt-5 mb-5">
      <ToastContainer />
      <div className="search-container container d-flex align-items-center mb-5">
        <div className="select-box">
          <label>Lead Type</label>
          <Select
            value={selectedLeadType}
            onChange={handleLeadTypeChange}
            options={leadTypeOptions}
            placeholder="Select a lead type"
          />
        </div>
        <div className="select-box">
          <label>Category</label>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            options={categoryOptions}
            placeholder="Select a category"
          />
        </div>
        <div className="input-box">
          <label>Product</label>
            <Form.Control
              type="text"
              value={productQuery}
              onChange={handleProductInputChange}
              placeholder="Enter product name"
              style={{ height: '40px',  }}
            />
          
        </div>
        <div>
          <Button variant="primary" onClick={handleSearch} className="search-button mt-4">
            Search
          </Button>
        </div>
        
      </div>
        <Row>
        <Col md={3}>
                {bannerHeading()}
                {banner_adstr.map((banner, index) => (
                    (index % 2 === 0) && (
                        <Row className='m-5 justify-content-md-center' key={index}>
                            <Card className='text-center' style={{ width: '400px' }}>
                                    <img variant="top" src={`${ImageUrl}/storage/tradeAds/${banner.image_path}`} 
                                    width="100%" 
                                    onClick={() => handleTradeImageClick(banner.image_path)}
                                    />
                            </Card>
                        </Row>
                    )))}
        </Col>
        <Col md={6}>
          <Row>{renderLeadsAndBanners()}</Row>
        </Col>
        <Col md={3}>
                {bannerHeading()}
                {banner_adstr.map((banner, index) => (
                    (index % 2 === 1) && (
                        <Row className='m-5 justify-content-md-center' key={index}>
                            <Card className='text-center' style={{ width: '400px' }}>
                                    <img variant="top" src={`${ImageUrl}/storage/tradeAds/${banner.image_path}`} 
                                    width="100%" 
                                    onClick={() => handleTradeImageClick(banner.image_path)}
                                    />
                            </Card>
                        </Row>
                    )))}
        </Col>
        </Row> 
        
        
        <Modal show={selectedImage !== null} onHide={handleCloseImageModal} centered>
        <Modal.Body>
          {/* Display the selected image in full screen */}
          <img src={selectedImage} alt="Full Screen" style={{ width: '100%', height: 'auto' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
        
      
    </Container>
  );
}

export default SearchBox;