import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
  } from 'cdbreact';


const Sidebar = () => {
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'white', backgroundColor:'orange' }}>
        <CDBSidebar >
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
            <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
            B2bLeads.in
            </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
            <NavLink exact to="/admin" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/admin/postlead" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="table">Leads</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/admin/banner-ad" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="user">Banner Ads</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/admin/banner-trade" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="chart-line">Banner Trade Ads</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/admin/user" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="chart-line">Users</CDBSidebarMenuItem>
            </NavLink>

            {/* <NavLink exact to="/hero404" target="_blank" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="exclamation-circle">404 page</CDBSidebarMenuItem>
            </NavLink> */}
            </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
            <div
            style={{
                padding: '20px 5px',
            }}
            >
            <Link className='nav-link' to='/logout' >Logout</Link>
            </div>
        </CDBSidebarFooter>
        </CDBSidebar>
  </div>
  );
};

export default Sidebar;