import React, { useState, useEffect } from 'react';
import api from '../../api';
import { useAuth } from '../../Context/AuthContext';
import Swal from 'sweetalert2';

function LeadSubmissionForm( { onLeadSubmit }) {
  const { isAdmin, user} = useAuth();
  const [leadType, setLeadType] = useState('buy');
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [category, setCategory] = useState('electronics');
  const [productName, setProductName] = useState('');
  const [images, setImages] = useState(null);
  const [businessType, setBusinessType] = useState('manufacturer');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [users, setUsers] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Prepare the data to be sent to the server
    const leadData = {
      lead_title: name,
      lead_description: message,
      lead_category: category,
      lead_location: city,
      product_name: productName,
      image_path: images,
      business_type: businessType,
      lead_type: leadType,
      user_id : isAdmin ? userId : user.id,
    };
    // Create a FormData object to send files
    const formData = new FormData();
    formData.append('image', images);
    Object.entries(leadData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    try {
      // Now you can send the request with the CSRF token
      const response = await api.post('/leads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Handle the success response
      setSuccessMessage(response.data.message);
      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Thank you for your post. It will be uploaded in 24 hrs.',
      });
      onLeadSubmit();
    } catch (error) {
      // Handle any errors here
      setErrorMessage(error.response?.data?.message || 'An error occurred while submitting the lead.');
    }
  };

  useEffect(() => {
    // Fetch users when the component mounts
    api.get('/users')
        .then(response => {
            setUsers(response.data.users);
        })
        .catch(error => {
            console.error('Error fetching users:', error);
        });
}, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col"> 
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

          <form onSubmit={handleSubmit} className='mb-3'>
            <div className="mb-3">
              <label>Lead Type:</label>
              <select
                className="form-select"
                value={leadType}
                onChange={(e) => setLeadType(e.target.value)}
              >
                <option value="buy">Buy Lead</option>
                <option value="sell">Sell Lead</option>
                <option value="distributorship">Distributorship Available</option>
                <option value="export">Export Lead</option>
                <option value="import">Import Lead</option>
                <option value='distwanted'>Distributorship wanted</option>
              </select>
            </div>
            <div className="mb-3">
              <label>Type of Business:</label>
              <select
                className="form-select"
                value={businessType}
                onChange={(e) => setBusinessType(e.target.value)}
              >
                <option value="manufacturer">Manufacturer</option>
                <option value="stockiest">Stockiest</option>
                <option value="wholesaler">Wholesaler</option>
                <option value="distributor">Existing Distributor</option>
                <option value="retailer">Retailer</option>
                <option value="importer">Importer</option>
                <option value="exporter">Exporter</option>
              </select>
            </div>

            <div className="mb-3">
              <label>Name:</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-3">
            {businessType === 'importer' || businessType === 'exporter'|| leadType ==='export' || leadType ==='import' ? (<label>Country:</label>): (<label>City:</label>)}
              
              <input
                type="text"
                className="form-control"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label>Category:</label>
              <select
                className="form-select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="Agri & Food Processing Machinery & Equipment">Agri & Food Processing Machinery & Equipment</option>
                <option value="Alcoholic Beverages, Tobacco & Related Products">Alcoholic Beverages, Tobacco & Related Products</option>
                <option value="Ayurvedic  & Herbal products">Ayurvedic  & Herbal products</option>
                <option value="Baby products">Baby products</option>
                <option value="Bags, Baggages & Suitcases">Bags, Baggages & Suitcases</option>
                <option value="Bar Accessories and Related Products">Bar Accessories and Related Products</option>
                <option value="Books, Magazines, Music & Entertainment">Books, Magazines, Music & Entertainment</option>
                <option value="Builders Hardware, Construction Material & Equipment">Builders Hardware, Construction Material & Equipment</option>
                <option value="Carpets, Mats, Rugs, Floorings">Carpets, Mats, Rugs, Floorings</option>
                <option value="Chemicals & Allied Products">Chemicals & Allied Products</option>
                <option value="Computer Software & Hardware">Computer Software & Hardware</option>
                <option value="Cosmetics, Toiletries  Hygiene & Personal care Products">Cosmetics, Toiletries  Hygiene & Personal care Products</option>
                <option value="Electrical Household & Other Goods, Components">Electrical Household & Other Goods, Components</option>
                <option value="Electronics & Communications">Electronics & Communications</option>
                <option value="Elevators & Escalators">Elevators & Escalators</option>
                <option value="Environment">Environment</option>
                <option value="Farm Inputs - Fertilizers, Pesticides & Seeds">Farm Inputs - Fertilizers, Pesticides & Seeds</option>
                <option value="Farm Products - Grains, Fruits Spices, etc">Farm Products - Grains, Fruits Spices, etc</option>
                <option value="Food and beverages">Food and beverages</option>
                <option value="Fish & Fish Products">Fish & Fish Products</option>
                <option value="Fitness Equipment & Sports Goods">Fitness Equipment & Sports Goods</option>
                <option value="Fuel & Energy Resources">Fuel & Energy Resources</option>
                <option value="Furniture, Interior Decoration & Furnishings">Furniture, Interior Decoration & Furnishings</option>
                <option value="Gems & Jewellery">Gems & Jewellery</option>
                <option value="Glass & Ceramics">Glass & Ceramics</option>
                <option value="Handicrafts, Gifts & Decorative">Handicrafts, Gifts & Decorative</option>
                <option value="Heating, Air Conditioner & Ventilation">Heating, Air Conditioner & Ventilation</option>
                <option value="Health and beauty">Health and beauty</option>
                <option value="Horticulture - Flowers, Plants & Related Products">Horticulture - Flowers, Plants & Related Products</option>
                <option value="Hotel & Restaurant - Equipments & Products">Hotel & Restaurant - Equipments & Products</option>
                <option value="Household & General Merchandise">Household & General Merchandise</option>
                <option value="Home Furnishings">Home Furnishings</option>
                <option value="Industrial Goods & Supplies">Industrial Goods & Supplies</option>
                <option value="Industrial Machinery & Plants & Supplies">Industrial Machinery & Plants & Supplies</option>
                <option value="Information Databases">Information Databases</option>
                <option value="Jute & Coir - Raw Material, Processed & Related Machinery">Jute & Coir - Raw Material, Processed & Related Machinery</option>
                <option value="Kitchen Utensils & Appliances">Kitchen Utensils & Appliances</option>
                <option value="Lab Instruments & Suppliers">Lab Instruments & Suppliers</option>
                <option value="Leather & Leather Products">Leather & Leather Products</option>
                <option value="Lights & Lighting">Lights & Lighting</option>
                <option value="Livestock, Poultry, Meat & Animal Products">Livestock, Poultry, Meat & Animal Products</option>
                <option value="Marine Equipment & Machinery">Marine Equipment & Machinery</option>
                <option value="Medical , HealthCare & Surgical">Medical , HealthCare & Surgical</option>
                <option value="Mineral & Metal Products">Mineral & Metal Products</option>
                <option value="Metallic Elements">Metallic Elements</option>
                <option value="Non Metallic Elements">Non Metallic Elements</option>
                <option value="Office Equipment, Stationery & Supplies">Office Equipment, Stationery & Supplies</option>
                <option value="Ores & Minerals">Ores & Minerals</option>
                <option value="Paper & Paper Products">Paper & Paper Products</option>
                <option value="Packaging products">Packaging products</option>
                <option value="Petroleum, Oil & Related Products">Petroleum, Oil & Related Products</option>
                <option value="Pets & Pet Supplies">Pets & Pet Supplies</option>
                <option value="Photographic & Optical Equipment">Photographic & Optical Equipment</option>
                <option value="Plastic Products">Plastic Products</option>
                <option value="Pollution Control Equipments">Pollution Control Equipments</option>
                <option value="Power Plants">Power Plants</option>
                <option value="Printing & Display Products">Printing & Display Products</option>
                <option value="Processed Food & Beverages Products & By Products">Processed Food & Beverages Products & By Products</option>
                <option value="Refrigeration, Cold Storage">Refrigeration, Cold Storage</option>
                <option value="Rubber & Related Items">Rubber & Related Items</option>
                <option value="Safety & Security Equipment">Safety & Security Equipment</option>
                <option value="Shipping Service">Shipping Service</option>
                <option value="Sports Goods and Entertainment">Sports Goods and Entertainment</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Textiles, Garment, Fashion Accessories & Footwear">Textiles, Garment, Fashion Accessories & Footwear</option>
                <option value="Tools & Instruments">Tools & Instruments</option>
                <option value="Toys & Fancy products">Toys & Fancy products</option>
                <option value="Transportation - Air, Rail, Auto Components">Transportation - Air, Rail, Auto Components</option>
                <option value="Watches & Clocks">Watches & Clocks</option>
                <option value="Wood - Raw Material, Processed & Related Machinery">Wood - Raw Material, Processed & Related Machinery</option>

              </select>
            </div>
            {isAdmin && (
              <div className="mb-3">
              <label htmlFor="userId">Select User:</label>
              <select id="userId" name="userId" 
              value ={userId}
              onChange={(e) => setUserId(e.target.value)}
              >
                  {users.map(user => (
                      <option key={user.id} value={user.id}>
                          {user.name}
                      </option>
                  ))}
              </select>
              </div>
            )}

            <div className="mb-3">
              <label>Product Name:</label>
              <input
                type="text"
                className="form-control"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label>Image :</label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={(e) => setImages(e.target.files[0])}
              />
            </div>
            

            <div className="mb-3">
              <label>Message (200 characters):</label>
              <textarea
                className="form-control"
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                maxLength="200"
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit Lead
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LeadSubmissionForm;
