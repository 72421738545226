import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import BannerAdForm from '../BannerAdForm';
import BannerAdTradeForm from '../BannerAdTradeForm';
import BannerAdList from '../BannerAdList';
import BannerAdTradeList from '../BannerAdTradeList';

function Advertise(user) {
    const [isBannerSubmitted, setBannerSubmitted] = useState(false);
    const [isBannerAdSubmitted, setBannerAdSubmitted] = useState(false);

    const handleBannerSubmission = () => {
        // Update the state to trigger a re-render of LeadList
        setBannerSubmitted((prev) => !prev);
      };

    const handleBannerAdSubmission =() =>{
        setBannerAdSubmitted((prev) => !prev);
    };
    return (
        <div className='container'>
             {/* <Alert variant="danger">
                <Alert.Heading>Note:</Alert.Heading>
                <p>
                Advertisements will be deleted with a refund of 75% only if found against our policy and irrelevant headings, namely matrimonial, real estate, automobile, personal, sale of used household items, jobs, recruitment, etc.,
                </p>
             </Alert> */}
             <>
             <div className='row mt-5'>
                <div className="col-md-6 text-center" style ={{boxShadow: "0 4px 21px -12px rgba(0, 0, 0, 0.66)"}}>
                    {/* <p>
                    10 days - Rs.400<br/>
                    Monthly – Rs. 1000
                    </p> */}
                    <BannerAdForm onBannerSubmit={handleBannerSubmission} user= {user}/>
                </div>
                <div className="col-md-6 text-center " style ={{boxShadow: "0 4px 21px -12px rgba(0, 0, 0, 0.66)"}} >
                    {/* <p>
                    10 days - Rs.400<br/>
                    Monthly – Rs. 1000
                    </p> */}
                    <BannerAdTradeForm onBannerSubmit={handleBannerAdSubmission}  user= {user}/>
                </div>
             </div>
             <BannerAdList key={`bannerList-${isBannerSubmitted}`} user = {user}/>
             <BannerAdTradeList key={`tradeList-${isBannerAdSubmitted}`} user = {user} />
             </>
             
        </div>
    );
}

export default Advertise;