import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaTrash } from 'react-icons/fa';
import api from '../api';
import Pagination from './Pagination';
import * as XLSX from 'xlsx';

function UserList() {
  
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchUsers = () => {
    const apiEndpoint = `/adusers?page=${currentPage}&count=8`;
    api.get(apiEndpoint)
      .then((response) => {
        setUsers(response.data.data);
        console.log(users);
        setTotalPages(response.data.last_page);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleUserDelete = (userId) => {
    api.delete(`/deleteUser/${userId}`)
      .then(() => {
        fetchUsers();
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
      });
  };
  const exportToExcel = () => {
    // Prepare the data for export
    const data = users.map((user) => ({
      Name: user.name,
      FirmName: user.firm_name,
      Email: user.email,
      Mobile: user.mobile,
      City: user.city,
    }));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Create a new worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    // Save the workbook to a file
    XLSX.writeFile(workbook, `UserList_${new Date().toISOString().slice(0, 10)}.xlsx`);
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage]);

  return (
    <div className="container mb-2">
      <div className='d-flex justify-content-between align-items-center col-md-10'>
        <h3 className="my-2">User List</h3>
        <div>
          <button className="btn btn-primary" onClick={exportToExcel}>Download Excel</button>
        </div>
      </div>
      <div className='d-flex justify-content-center mt-2'>
        <ul className="col-md-10">
          {users !== undefined && users.map((user) => (
            <li key={user.id} className="list-group-item border rounded">
              <div className='row d-flex align-items-center justify-content-center m-1'>
                <div className='col-md-2'>
                  <h6>{user.name}</h6>
                </div>
                <div className='col-md-2'>
                  <h6>{user.firm_name}</h6>
                </div>
                <div className='col-md-3'>
                  <h6>{user.email}</h6>   
                </div>
                <div className='col-md-2'>
                  <h6>{user.mobile}</h6>
                </div>
                <div className='col-md-2'>
                  <h6>{user.city}</h6>
                </div>

                <div className='col-md-1'>
                  <button
                    className="btn btn-link"
                    onClick={() => handleUserDelete(user.id)}
                  >
                    <FaTrash fill="red" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className='d-flex justify-content-center mt-2'>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
    </div>
  );
}

export default UserList;
