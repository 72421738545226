import React from 'react'
import LeadList from './LeadList';
import UserSidebar from '../UserSidebar'

function UserPostlead(user) {
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)' }}>
      <UserSidebar />
      <div className="container">
      <LeadList user={user} />
    </div>
    </div>
    
  )
}

export default UserPostlead