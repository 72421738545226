import React, { useState } from 'react';
import api from '../../api';
import { toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [country,setCountry] =useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create an object with the data to send to the backend
    const data = {
      name,
      email,
      country,
      message,
    };

    try {
      const response = await api.post('/contact', data);

      console.log('Server Response:', response.data);

        // Show success toast
        toast.success('Message sent successfully!', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      // Handle the response from the server, e.g., show a success message.
      console.log('Server Response:', response.data);

      // Clear the form or perform necessary actions.
      setName('');
      setEmail('');
      setMessage('');
      setCountry('');
    } catch (error) {
      // Handle any errors, e.g., show an error message.
      console.error('Error:', error);
      toast.error('Failed to send message. Please try again later.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
      <div>
            <p>
              <span style={{ fontWeight: 'bold' }}>Register office:</span><br />
              <span style={{ fontWeight: 'bold' }}>Paranji Enterprises,</span><br />
              Anna Nagar,<br />
              Pammal, <br />
              Chennai-600075<br />
              Tamil Nadu, India<br />
              <span style={{ fontWeight: 'bold' }}>Email:</span> info@b2bleads.in
            </p>
      </div>
        <div className="col-md-8">
          <h2 className="mb-4">Contact Us</h2>
          
          <form onSubmit={handleSubmit} className='mb-5 col-md-8 mx-auto'>
            <div className="form-group ">
              <label>Name:</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={handleNameChange}
                required
              />
            </div>

            <div className="form-group mt-3">
              <label>Email:</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>

            <div className="form-group mt-3">
              <label>Country:</label>
              <input
                type="text"
                className="form-control"
                value={country}
                onChange={handleCountryChange}
                required
              />
            </div>

            <div className="form-group mt-3">
              <label>Message:</label>
              <textarea
                className="form-control"
                value={message}
                onChange={handleMessageChange}
                required
              />
            </div>
            <div className='text-center'>
                <button type="submit" className="btn btn-primary mt-3">
                  Submit
                </button>
            </div>
            
          </form>
          <div><p>We will make every efforts to respond to your message within 48 hours.</p></div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Contact;
