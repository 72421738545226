// import React, {useEffect} from 'react';
// import Cookies from 'js-cookie';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../Context/AuthContext';

// const Logout = () => {
//   const navigate = useNavigate();
//   const { logout} = useAuth();

//   const handleLogout = async () => {
//     try {
//       // Remove the authentication token
//       Cookies.remove('authToken');
//       logout(); 

//       // Redirect to the login page or any other page after logout
//       navigate('/login');
//     } catch (error) {
//       console.error('Logout failed:', error);
//     }
//   };

//   // Call handleLogout when the component renders
//   useEffect(() => {
//     handleLogout();
//   }, [handleLogout]); // The empty dependency array ensures this effect runs once when the component mounts

//   return <div>Logging out...</div>; // You can render a loading message or redirect in a more user-friendly way
// };

// export default Logout;

import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  // Call handleLogout when the component renders
  useEffect(() => {
    const handleLogout = async () => {
      try {
        // Remove the authentication token
        Cookies.remove('authToken');
        logout();

        // Redirect to the login page or any other page after logout
        navigate('/login');
      } catch (error) {
        console.error('Logout failed:', error);
      }
    };

    handleLogout();
  }, [logout, navigate]); // Include dependencies if needed

  return <div>Logging out...</div>;
};

export default Logout;
