import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: 'https://api.b2bleads.in/api',
  //baseURL:'http://localhost:8000/api',
});

api.interceptors.request.use((config) => {
  const token =  Cookies.get('authToken');
  config.headers.Authorization = `Bearer ${token}`
  return config;
})

api.interceptors.response.use((response) => {
  return response
}, (error) => {
  const {response} = error;
  if (response.status === 401) {
    Cookies.remove('authToken')
    // window.location.reload();
  } else if (response.status === 404) {
    //Show not found
  }

  throw error;
})

export default api