// ChangePassword.js
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import api from '../api';
import UserSidebar from '../UserSidebar';

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('/change-password', formData);
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error changing password. Please try again.');
    }
  };

  return (
    <Container fluid style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)' }}>
      <UserSidebar />
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <h2 className="mt-3">Change Password</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="currentPassword">
                <Form.Label>Current Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="newPassword">
                <Form.Label>New Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Change Password
              </Button>
            </Form>

            {message && (
              <Alert variant="info" className="mt-3">
                {message}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ChangePassword;
