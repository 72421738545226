import React from 'react';
import { Container, Table } from 'react-bootstrap';

const PricingPage = () => {
  return (
    <Container className='mt-5' style={{ maxWidth: '60%' }}>
      <Table striped bordered hover responsive>
      <thead className="table-primary text-center">
          <tr>
            <th  colSpan="7" style={{color:"navy"}}><h1>Pricing Information</h1></th>
          </tr>
        </thead>
        <tbody className='text-center'>
        <tr>
            <td className="w-20">
              <h5 style={{color:"navy"}}>Description </h5>
            </td>
            <td className="w-20">
            <h5 style={{color:"navy"}}>Post</h5>
            </td>
            <td className="w-20">
            <h5 style={{color:"navy"}}>Access</h5>
            </td>
            <td className="w-20">
            <h5 style={{color:"navy"}}>No. of Days</h5>
            </td>
            <td className="w-20">
            <h5 style={{color:"navy"}}>With in India</h5>
            </td>
            <td className="w-20">
            <h5 style={{color:"navy"}}>Overseas</h5>
            </td>
          </tr>
          <tr>
            <td >Trade Lead*</td>
            <td >Unlimted</td>
            <td >4 Leads per Week</td>
            <td >7days</td>
            <td>Free</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Banner ads (Service industries)</td>
            <td style={{color:"red"}}>First post <br />(FREE) <br />for 10 days*</td>
            <td>Unlimited</td>
            <td> <p>10 days</p> <p>30 days</p></td>
            <td><p> Rs.200 </p><p>Rs.500</p></td>
            <td><p> US $5 </p><p>US$ 12</p></td>
          </tr>
          <tr>
            <td>Banner ads (Trade leads*)</td>
            <td style={{color:"red"}}>First post <br />(FREE) <br />for 10 days*</td>
            <td>Unlimited</td>
            <td> <p>10 days</p> <p>30 days</p></td>
            <td><p> Rs.200 </p><p>Rs.500</p></td>
            <td><p> US $5 </p><p>US$ 12</p></td>
          </tr>
        </tbody> 
      </Table>
      <div className="row m-3">
      <span>
      Trade leads*: Buy/sell/distributorship/import/export leads<br />
      Banner ads: Trade (70+ categories) &amp; Service industries 300+ categories.
      </span>
      </div>
      <div className="row m-3">
        <span style={{color:"red"}}>
        First post (FREE) : Get 20 days display on payment of 10 days (on first post only)
        </span>
      </div>
      <div className="row ms-2">
        <ul>
        <li>Post your ad in advertisement page.</li>
        <li>Advertiser can display their contact details in the banner ads.</li>
        <li>Trade lead ads will be published in pages based on product category and leads concern.
            only. </li>
        <li>Read FAQ over the list of banner ads " not to be posted" </li>
        {/* <li>Banner display ad from matrimonial/real estate/automobile/job/share market and
        other unrelated industries to trade may please be avoided. </li> */}
        </ul>
      </div>
    </Container>
  );
};


export default PricingPage;
