import React from 'react';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className='not'>
      <h2 className='notHead'>404 - Not Found</h2>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
