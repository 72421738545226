import { React, useState } from 'react';
import LeadList from './LeadList';
import Sidebar from './Sidebar';
import { Modal, Button, Col,Row } from 'react-bootstrap';
import LeadSubmissionForm from './LeadSubmissionForm/LeadSubmissionForm';

function AdminLead(user) {
  const [isLeadSubmitted, setLeadSubmitted] = useState(false);
  const [showLeadSubmissionModal, setShowLeadSubmissionModal] = useState(false);

  const handleLeadSubmission = () => {
    // Update the state to trigger a re-render of LeadList
    setLeadSubmitted(true);
    setShowLeadSubmissionModal(false);
  };


  const handleModalOpen = () => {
    setShowLeadSubmissionModal(true);
  };

  const handleModalClose = () => {
    setShowLeadSubmissionModal(false);
  };

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)', backgroundColor:'white' }}>
      <Sidebar />
      <div className="container mt-2">
        <Row>
        <Col md={9}>
        </Col>
        <Col md={3} >
          <Button variant="primary" onClick={handleModalOpen} className="ml-auto">
            Create Lead
          </Button>
        </Col>
        </Row>
        
        <Modal show={showLeadSubmissionModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Lead Submission Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LeadSubmissionForm onLeadSubmit={handleLeadSubmission} user={user} />
          </Modal.Body>
        </Modal>
        <LeadList key={isLeadSubmitted} />
      </div>
    </div>
  );
}

export default AdminLead;