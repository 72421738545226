import React, { useEffect, useState }  from 'react';
import Sidebar from './Sidebar'; 
import api from '../api';
import UserList from './UserList';

function AdminUsers() {
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)', backgroundColor:'white' }}>
      <Sidebar />
      <div className="content">
       <UserList />
      </div>
    </div>
  )
}

export default AdminUsers