// components/BannerAdModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from '../api';
import { FormGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parse, format } from 'date-fns';
import { useAuth } from '../Context/AuthContext';

function BannerAdModal({ show, onClose, banner }) {
  const {isAdmin, user} =useAuth();
  const [selectedIndustry, setSelectedIndustry] = useState(banner ? banner.service_industry : '');
  const [expirationDate, setExpirationDate] = useState(banner ? new Date(banner.expiration_date) : new Date());

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
  };

  const handleExpirationDateChange = (date) => {
    setExpirationDate(date);
  };

  
  useEffect(() => {
    if (banner) {
      setSelectedIndustry(banner.service_industry);
      setExpirationDate(new Date(banner.expiration_date));
    } else {
      setExpirationDate(new Date());
    }
  }, [banner]);

  const handleSave = async () => {
    try {
      if (banner) {
        // If the banner already exists, update it
        const formattedDate = format(expirationDate, 'yyyy-MM-dd HH:mm:ss');
        await api.put(`/banner/${banner.id}`, { service_industry: selectedIndustry, expiration_date:formattedDate });
      }

      // Close the modal after saving
      onClose();
    } catch (error) {
      console.error('Error saving banner ad:', error);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{banner ? 'Edit Banner Ad' : 'Add New Banner Ad'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="serviceIndustry">
            <Form.Label>Service Industry</Form.Label>
            <Form.Select
              value={selectedIndustry}
              onChange={handleIndustryChange}
            >
              <option value="" disabled>Select an industry</option>
              <option value="logistic">Logistic</option>
              <option value="airsea">Air/Sea Cargo</option>
              <option value="bankinsurance">Bank & Insurance</option>
              <option value="msmeloan">MSME Loan Facilitators</option>
            <option value="transportparcel">Transport/Parcel/Courier Service</option>
            <option value="packing">Packing Industries</option>
            <option value="digitalmarketing">Digital Marketers</option>
            <option value="franchise">Franchise Available</option>
            <option value="financiallegal">Financial and Legal Services</option>
            <option value="consultancy">Consultancy</option>
            <option value="facilitymanagement">Facility Management</option>
            <option value="warehousegodown">Warehouse/Godown</option>
            <option value="testinglab">Testing Lab & Testing Services</option>
            <option value="others">Others Related to Business</option>
            </Form.Select>
          </Form.Group>
          <FormGroup>
            <Form.Label>

            </Form.Label>
          </FormGroup>
          {isAdmin &&(
          <Form.Group controlId="expirationDate">
            <Form.Label>Expiration Date</Form.Label>
            <DatePicker
              selected={expirationDate}
              onChange={handleExpirationDateChange}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BannerAdModal;
