import React from 'react'
import BannerAdTradeList from './BannerAdTradeList'
import UserSidebar from '../UserSidebar'

function UserTradAds(user) {
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)' }}>
      <UserSidebar />
      <div className="container">
      <BannerAdTradeList user = {user}/>
    </div>
    </div>
  )
}

export default UserTradAds