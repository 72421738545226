import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer/Footer'

function ConditionalFooter() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/admin/postlead' ||location.pathname === '/admin/banner-ad'
  || location.pathname === '/admin/banner-trade' ||location.pathname === '/admin'  ;

  if (isLoginPage) {
    return null; // Don't render the footer on the login and registration pages
  }

  return <Footer />;
}

export default ConditionalFooter;