import Accordion from 'react-bootstrap/Accordion';

function FAQPage() {
  return (
    <div className='container mt-3'>
      <div>
        <h3>Frequently asked Question</h3>
      </div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is B2Bleads.in?</Accordion.Header>
        <Accordion.Body>
          <ul>
          <li>B2B leads.in is a  platform to display trade leads on Buy/Sell/Distributors wanted / distributorship available / export & Import leads across India and overseas at  “Zero Cost “</li>
          <li> Service providers  can  display Ad banner with contact details  of  related to Trade activities and as well introduction  on Start-up, MSME, finance available. AMC, logistics, transport, cargo , digital marketing, Auditing service, machinery and 300+ categories.</li></ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Why we are unique?</Accordion.Header>
        <Accordion.Body>
          <p>
            We understand the process of business (traders/service provider/distributors/manufactures) who are always in the lookout for relevant leads and instant contact details to grow their business constantly, among different segments of business community.
          </p>
          {/* <p>
          We are unique, simple and cost effective by letting user to search relevant leads on need basis and as well as instant access to millions of eyes to our banner ads on trade leads and service providers on day to-day basis, at ‘Zero cost”
          </p>
           */}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
      <Accordion.Header>Who can register?</Accordion.Header>
      <Accordion.Body>
        <li>
        Traders with GST, IEC (India) and accredited exporters & Importers from overseas can sign to post Leads and advertisement banner.
        </li>
        <li>
        Also individuals who wish to buy bulk quantity/farmers who wish to sell  pre-harvesting                                  products  can register.
        </li>
      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
      <Accordion.Header>Who can post ?</Accordion.Header>
      <Accordion.Body>
      Registered user only can  post and access  contact details @ 4  trade leads  per week on  Buy/Sell/Dist/Exim leads. 
      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
      <Accordion.Header>Who can view ?</Accordion.Header>
      <Accordion.Body>
      Anyone can see advertisement banner  on Trade leads / service provider categories without login for instant access to grow Trade.
      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
      <Accordion.Header>What are the types of services  offered by  B2Bleads.in ?</Accordion.Header>
      <Accordion.Body>
        <li>
        <b> a) Trade leads : </b> <br />
        Users can post and view (on Log in ) Trade leads ( 70 + categories ) at zero cost.  
        </li>
        <li>
        <b>
        b) Trade leads (Ad Banner) : <br />
        </b>
        Registered Traders  only can post Trade leads advt   banners  with contact   details in 70 + categories at zero cost.
        </li>
        <li>
          <b> c) Service Providers (Ad Banners)  :</b> <br />
          Registered Service providers  only can post  banners ads with contact details  in 300+ categories  at zero cost.
        </li>
        {/* <li>
          <b>Export/Import leads (Ad banners):</b>
          Exim needs can be  posted @ US$ 5/ ( 10 days)  and US$ 12 for 30 days.
        </li> */}
      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
      <Accordion.Header>When will my add be activated ? </Accordion.Header>
      <Accordion.Body>
        <li>
        Usually we activate within 2 days from the date of posting after checking manually.
        </li>
        <li>
        B2bleads.in reserves all rights to approve any advt banner at its sole discretion.
        </li>
      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
      <Accordion.Header>What are the businesses that are not accepted by B2Bleads.in?</Accordion.Header>
      <Accordion.Body>
        <li>
        Adult Products  : Adult goods and services which include pornography and other sexually suggestive materials (including literature, imagery and other media); escort or prostitution services and massage parlors: Payment of potentially sexual related services.
        </li>
        <li>
        Illegal drugs, Replica products, Gambling, sharp objects, Arms and ammunition,  Tobacco and cigarettes, Liquors, currencies, Share market,  Matrimonial,  Real estate (Go down & warehouse rent exceptional) Animal parts, Live animal,  Crypto currencies, Human parts, Online trading of stocks and bonds etc, pornography, hacking and cracking, illegal goods.
        </li>
        <li>
        Miracle cure, work from home,  MLM marketing, Poonzi scheme, marijuana products,  political party donation, auction, unregulated charities, Timeshare. 
        </li>
        <li>
        Any product or service, which is not in compliance with all applicable laws and regulations whether federal, state, local or international including the laws of India.
        </li>
      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
      <Accordion.Header>Taxes: </Accordion.Header>
      <Accordion.Body>
      The Ministry of Finance, Government of India, has levied a Goods & Services Tax of 18% on the value of Advertisement banner charges includes 18% GST the service tax on the SAC code no: 998365 - Sale of Internet advertising space Internet advertising service included and payable by us. 
      </Accordion.Body>
      </Accordion.Item>
      
    </Accordion>
    <p>For any other queries please send a request feedback to info@b2bleads.in thru contact page to help us serve you better, as we don’t have phone support at this moment.</p>
    </div>
    
  );
}

export default FAQPage;