import React, { useEffect, useState } from 'react';
import api from '../api';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaTrash } from 'react-icons/fa'; 
import { FaPen } from 'react-icons/fa';// FaPen Import the trash icon from react-icons
import EditLeadModal from './EditLeadModal';
import { ImageUrl } from '../global';
import { useAuth } from '../Context/AuthContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parse, format } from 'date-fns';
import Pagination from './Pagination';
import ImageModal from './ImageModal';
import Swal from 'sweetalert2';

function LeadList() {
  const { isAdmin, user } = useAuth();
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowImageModal(true);
  };


  const handleEditClick = (lead) => {
    setShowEditModal(true);
    setSelectedLead(lead);
  };

  const handleEditSuccess = () => {
    setShowEditModal(false);
    //setSelectedLead(null);
  };

  const handleCancelEdit = () => {
    setShowEditModal(false);
    //setSelectedLead(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  

  const fetchLeads = () => {
    const apiEndpoint = isAdmin ? `/leads?page=${currentPage}&count=8` : `/leads?filter[user_id]=${user.id}&page=${currentPage}&count=8`;

    api.get(apiEndpoint)
      .then((response) => {
        setLeads(response.data.data);
        setTotalPages(response.data.last_page);
      })
      .catch((error) => {
        console.error('Error fetching leads:', error);
      });
  };

  const handleLeadDelete = (leadId) => {
    api.delete(`/leads/${leadId}`)
      .then(() => {
        fetchLeads();
      })
      .catch((error) => {
        console.error('Error deleting lead:', error);
      });
  };

  const handleLeadApprove = (leadId) => {
    api.get(`/lead-approve/${leadId}`)
      .then(() => {
        fetchLeads();
      })
      .catch((error) => {
        console.error('Error deleting lead:', error);
      });
  };

  const handleUpdateExpirationDate = (leadId) => {
    if (expirationDate) {
      const formattedDate = format(expirationDate, 'yyyy-MM-dd HH:mm:ss');
      api.patch(`/update-expiration-date/${leadId}`, { expiration_date: formattedDate })
        .then(() => {
          // Handle success if needed
          fetchLeads();
          console.log('Expiration date updated successfully');
        })
        .catch((error) => {
          // Handle error if needed
          console.error('Error updating expiration date:', error);
        });
    }
  };
  const isLeadExpired = (expirationDate) => {
    const currentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    return expirationDate < currentDate;
  };

  const handleRepost= async (leadId) =>{
    try {
      // Make an API call to repost the lead
      await api.post(`/repost-lead/${leadId}`);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Thank you for your post. It will be uploaded in 24 hrs.',
      });
      // After successful repost, you may want to update the leads
      fetchLeads();
  
      console.log('Lead reposted successfully');
    } catch (error) {
      // Handle error if needed
      console.error('Error reposting lead:', error);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [currentPage]);

  return (
    <div className="container mb-2">
      <div className='d-flex justify-content-center'>
        <h3 className="my-2">Lead List</h3>
      </div>
      <div className='d-flex justify-content-center mt-2'>
        <ul className="col-md-10">
          {leads.map((lead) => (
            <li key={lead.id} className="list-group-item border rounded">
              <div className='row d-flex align-items-center justify-content-center m-1'>
                <div className='col-md-1'>
                  <img src={`${ImageUrl}/storage/${lead.image_path}`} alt="" height='40' width='40' 
                    onClick={() => handleImageClick(`${ImageUrl}/storage/${lead.image_path}`)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div className='col-md-5'>
                  <h5>{lead.lead_title}</h5>
                  {lead.lead_type}
                </div>
                <div className="col-md-1">
                  <button className='btn btn-link' 
                  onClick={() => handleEditClick(lead)}
                  >
                    <FaPen fill="blue" />
                  </button>
                </div>
                {isAdmin && (lead.status === 'Created' || lead.status === "Closed") ? (
                  <div className='col-md-2 text-center'>
                    <button className="btn btn-success btn-sm" onClick={() => handleLeadApprove(lead.id)}>
                      Approve
                    </button>
                  </div>
                ) : (
                  <div className='col-md-2 text-center'>
                    {isLeadExpired(lead.expiration_date) ? (
                      <div>
                        <span className="text-danger">Inactive</span>
                        <button
                          className="btn btn-link"
                          onClick={() => handleRepost(lead.id)}
                        >
                          Repost
                        </button>
                      </div>
                    ) : (
                      lead.status
                    )}
                  </div>
                )}
                <div className='col-md-1'>
                  <button
                    className="btn btn-link"
                    onClick={() => handleLeadDelete(lead.id)}
                  >
                    <FaTrash fill="red" />
                  </button>
                </div>
                {isAdmin && (
                  <div className="col-md-2">
                    <label htmlFor="expirationDate" className="form-label">
                      Expiration Date
                    </label>
                    <DatePicker
                      selected={parse(lead.expiration_date.replace(';', ':'), 'yyyy-MM-dd HH:mm:ss', new Date())}
                      onChange={(date) => setExpirationDate(date)}
                      onBlur={() => handleUpdateExpirationDate(lead.id)}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      id="expirationDate"
                    />
                  </div>
                  
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className='d-flex justify-content-center mt-2'>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
      <EditLeadModal
        show={showEditModal}
        handleClose={handleCancelEdit}
        lead={selectedLead}
        onEditSuccess={handleEditSuccess}
      />
      {showImageModal && (
        <ImageModal imageUrl={selectedImageUrl} onClose={() => setShowImageModal(false)} />
      )}
    </div>
  );
}

export default LeadList;
