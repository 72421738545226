import React, { useState, useRef } from 'react';
import api from '../api';
import Swal from 'sweetalert2';
import { useAuth } from '../Context/AuthContext';

function BannerAdTradeForm({onBannerSubmit}) {

  const {isAdmin, user} =useAuth();
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedTradeLeadCategory, setSelectedTradeLeadCategory] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [validationError, setValidationError] = useState('');
  const imagePreviewRef = useRef(null);
  const [paymentOption, setPaymentOption] = useState('10days');
 
  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);
  };

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };
  const handleTradeLeadCategoryChange = (event) => {
    setSelectedTradeLeadCategory(event.target.value);
  };
  const calculatePaymentAmount = (option) => {
    if(user.country.toLowerCase() !== 'india'){
      return option === '10days' ? 5 : 12;
    }
    else {
      return option === '10days' ? 200 : 500;
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (validateImageSize(file) && validateImageDimensions(file)) {
      setSelectedImage(file);
      setValidationError('');
    } else {
      setSelectedImage(null);
      setValidationError('Invalid image file. Please upload a 500x500 pixel JPEG image with a maximum file size of 300KB.');
    }
  };

  const validateImageSize = (file) => {
    return file.size <= 300 * 1024; // 300KB (300 * 1024 bytes)
  };

  const validateImageDimensions = (file) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    return new Promise((resolve, reject) => {
      img.onload = () => {
        if (img.width === 500 && img.height === 500) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      img.onerror = () => {
        reject();
      };
    });
  };

  const handlePreview = () => {
    if (selectedImage) {
      imagePreviewRef.current.src = URL.createObjectURL(selectedImage);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('in handle function');
    if (selectedIndustry && selectedTradeLeadCategory && selectedImage) {
      // if(isAdmin){
        const bannerAdData = {
          "service_industry" : selectedIndustry,
          "trade_lead_category" : selectedTradeLeadCategory,
          "image_path": selectedImage,
          "user_id"  : user.user,
        };
        await api.post('/banneradtrades', bannerAdData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Thank you for your Banner Ad. It will be uploaded in 24 hrs.',
            });
            onBannerSubmit()
          })
          .catch((error) => {
            // Handle any errors here
            console.error('An error occurred while submitting the lead:', error);
          });
    
    //   }
    //   else{
    //     const amount = calculatePaymentAmount(paymentOption);
    //     var options = {
    //       "key": "rzp_live_bAbHG1YycclGNU",//"rzp_test_rp2bOy52KhJdo7", //
    //       "amount": user.country.toLowerCase() !== 'india' ? (amount *  100) : (amount * 100), // Convert amount to dollars if not from India
    //       "currency": user.country.toLowerCase() !== 'india'? "USD" : "INR",
    //       "name": "B2bleads.in",
    //       //"description": "Test Transaction",
    //       //"image": "https://example.com/your_logo",
    //       "handler":async function (response){
    //         const bannerAdData = {
    //           "service_industry" : selectedIndustry,
    //           "trade_lead_category" : selectedTradeLeadCategory,
    //           "image_path": selectedImage,
    //           "user_id"  : user.user,
    //       };
    //         try {
    //           const postresponse = await api.post('/banneradtrades', bannerAdData, {
    //             headers: {
    //               'Content-Type': 'multipart/form-data',
    //             },
    //           });
    //           console.log("post response",postresponse.data);
    //           const paymentVerificationResponse = await api.post('/payment', {
    //             paymentId: response.razorpay_payment_id,
    //             user_id:user.id,
    //             user_email: user.email,
    //             currency:'Inr',
    //             amount:amount,
    //             method:'razorpay',
    //             apiresponse:response,
    //             type:'banner-trade',
    //             type_id:postresponse.data.banneradTr.id,
    //           });
    //           Swal.fire({
    //             icon: 'success',
    //             title: 'Success!',
    //             text: 'Thank you for your Banner Ad. It will be uploaded in 24 hrs.',
    //           });
    //           onBannerSubmit();
    //           event.target.reset();
    //           setSelectedImage(null);
    //           setSelectedIndustry('');
    //           setSelectedTradeLeadCategory('');
    //         } catch (error) {
    //           console.error('An error occurred while submitting the banner ad:', error);
    //         }
              
    //       },
    //       "prefill": {
    //           "name": user.name,
    //           "email": user.email,
    //           "contact": user.mobile,
    //       },
    //       "theme": {
    //           "color": "#3399cc"
    //       }
    //      };

    //   var rzp1 = new window.Razorpay(options);
    //   rzp1.open();
    // }

    } else {
      alert(validationError || 'Please fill out all the required fields and select a valid image file.');
    }
  };

  return (
    <div className="container mb-5">
      <div className="row justify-content-center">
        <div>
          <h2 className="text-center mt-2">Submit a Trade Lead Banner Ad</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="industry">Select Industry:</label>
              <select id="industry" className="form-select" value={selectedIndustry} onChange={handleIndustryChange} required>
                <option value="" disabled>Select an industry</option>
                <option value="Agri & Food Processing Machinery & Equipment">Agri & Food Processing Machinery & Equipment</option>
                <option value="Alcoholic Beverages, Tobacco & Related Products">Alcoholic Beverages, Tobacco & Related Products</option>
                <option value="Ayurvedic  & Herbal products">Ayurvedic  & Herbal products</option>
                <option value="Baby products">Baby products</option>
                <option value="Bags, Baggages & Suitcases">Bags, Baggages & Suitcases</option>
                <option value="Bar Accessories and Related Products">Bar Accessories and Related Products</option>
                <option value="Books, Magazines, Music & Entertainment">Books, Magazines, Music & Entertainment</option>
                <option value="Builders Hardware, Construction Material & Equipment">Builders Hardware, Construction Material & Equipment</option>
                <option value="Carpets, Mats, Rugs, Floorings">Carpets, Mats, Rugs, Floorings</option>
                <option value="Chemicals & Allied Products">Chemicals & Allied Products</option>
                <option value="Computer Software & Hardware">Computer Software & Hardware</option>
                <option value="Cosmetics, Toiletries  Hygiene & Personal care Products">Cosmetics, Toiletries  Hygiene & Personal care Products</option>
                <option value="Electrical Household & Other Goods, Components">Electrical Household & Other Goods, Components</option>
                <option value="Electronics & Communications">Electronics & Communications</option>
                <option value="Elevators & Escalators">Elevators & Escalators</option>
                <option value="Environment">Environment</option>
                <option value="Farm Inputs - Fertilizers, Pesticides & Seeds">Farm Inputs - Fertilizers, Pesticides & Seeds</option>
                <option value="Farm Products - Grains, Fruits Spices, etc">Farm Products - Grains, Fruits Spices, etc</option>
                <option value="Food and beverages">Food and beverages</option>
                <option value="Fish & Fish Products">Fish & Fish Products</option>
                <option value="Fitness Equipment & Sports Goods">Fitness Equipment & Sports Goods</option>
                <option value="Fuel & Energy Resources">Fuel & Energy Resources</option>
                <option value="Furniture, Interior Decoration & Furnishings">Furniture, Interior Decoration & Furnishings</option>
                <option value="Gems & Jewellery">Gems & Jewellery</option>
                <option value="Glass & Ceramics">Glass & Ceramics</option>
                <option value="Handicrafts, Gifts & Decorative">Handicrafts, Gifts & Decorative</option>
                <option value="Heating, Air Conditioner & Ventilation">Heating, Air Conditioner & Ventilation</option>
                <option value="Health and beauty">Health and beauty</option>
                <option value="Horticulture - Flowers, Plants & Related Products">Horticulture - Flowers, Plants & Related Products</option>
                <option value="Hotel & Restaurant - Equipments & Products">Hotel & Restaurant - Equipments & Products</option>
                <option value="Household & General Merchandise">Household & General Merchandise</option>
                <option value="Home Furnishings">Home Furnishings</option>
                <option value="Industrial Goods & Supplies">Industrial Goods & Supplies</option>
                <option value="Industrial Machinery & Plants & Supplies">Industrial Machinery & Plants & Supplies</option>
                <option value="Information Databases">Information Databases</option>
                <option value="Jute & Coir - Raw Material, Processed & Related Machinery">Jute & Coir - Raw Material, Processed & Related Machinery</option>
                <option value="Kitchen Utensils & Appliances">Kitchen Utensils & Appliances</option>
                <option value="Lab Instruments & Suppliers">Lab Instruments & Suppliers</option>
                <option value="Leather & Leather Products">Leather & Leather Products</option>
                <option value="Lights & Lighting">Lights & Lighting</option>
                <option value="Livestock, Poultry, Meat & Animal Products">Livestock, Poultry, Meat & Animal Products</option>
                <option value="Marine Equipment & Machinery">Marine Equipment & Machinery</option>
                <option value="Medical , HealthCare & Surgical">Medical , HealthCare & Surgical</option>
                <option value="Mineral & Metal Products">Mineral & Metal Products</option>
                <option value="Metallic Elements">Metallic Elements</option>
                <option value="Non Metallic Elements">Non Metallic Elements</option>
                <option value="Office Equipment, Stationery & Supplies">Office Equipment, Stationery & Supplies</option>
                <option value="Ores & Minerals">Ores & Minerals</option>
                <option value="Paper & Paper Products">Paper & Paper Products</option>
                <option value="Packaging products">Packaging products</option>
                <option value="Petroleum, Oil & Related Products">Petroleum, Oil & Related Products</option>
                <option value="Pets & Pet Supplies">Pets & Pet Supplies</option>
                <option value="Photographic & Optical Equipment">Photographic & Optical Equipment</option>
                <option value="Plastic Products">Plastic Products</option>
                <option value="Pollution Control Equipments">Pollution Control Equipments</option>
                <option value="Power Plants">Power Plants</option>
                <option value="Printing & Display Products">Printing & Display Products</option>
                <option value="Processed Food & Beverages Products & By Products">Processed Food & Beverages Products & By Products</option>
                <option value="Refrigeration, Cold Storage">Refrigeration, Cold Storage</option>
                <option value="Rubber & Related Items">Rubber & Related Items</option>
                <option value="Safety & Security Equipment">Safety & Security Equipment</option>
                <option value="Shipping Service">Shipping Service</option>
                <option value="Sports Goods and Entertainment">Sports Goods and Entertainment</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Textiles, Garment, Fashion Accessories & Footwear">Textiles, Garment, Fashion Accessories & Footwear</option>
                <option value="Tools & Instruments">Tools & Instruments</option>
                <option value="Toys & Fancy products">Toys & Fancy products</option>
                <option value="Transportation - Air, Rail, Auto Components">Transportation - Air, Rail, Auto Components</option>
                <option value="Watches & Clocks">Watches & Clocks</option>
                <option value="Wood - Raw Material, Processed & Related Machinery">Wood - Raw Material, Processed & Related Machinery</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="tradeLeadCategory">Trade Lead Category:</label>
              <select id="tradeLeadCategory" className="form-select" value={selectedTradeLeadCategory} onChange={handleTradeLeadCategoryChange} required>
                <option value="" disabled>Select a category</option>
                <option value="buy">Buy Lead</option>
                <option value="sell">Sell Lead</option>
                <option value="distributorship">Distributorship Available</option>
                <option value="distwanted">Distributorship Wanted</option>
                <option value="export">Export Lead</option>
                <option value="import">Import Lead</option>
                {/* Add more trade lead categories as needed */}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="image">Upload Banner (500x500 pixels, max 300KB JPEG):</label>
              <input
                type="file"
                id="image"
                className="form-control"
                accept="image/jpeg"
                onChange={handleImageChange}
                required
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="paymentOption">Select Payment Option:</label>
              {
                (user.country.toLowerCase() !== 'india') ? (
                  <select id="paymentOption" className="form-select" value={paymentOption} onChange={handlePaymentOptionChange} required>
                    <option value="10days">10 Days ($5)</option>
                    <option value="monthly">Monthly ($12)</option>
                  </select>
                ) : (
                  <select id="paymentOption" className="form-select" value={paymentOption} onChange={handlePaymentOptionChange} required>
                    <option value="10days">10 Days (Rs.200)</option>
                    <option value="monthly">Monthly (Rs.500)</option>
                  </select>
                )
              }
            </div> */}
            {validationError && <div className="text-danger">{validationError}</div>}
            <div className="row mb-3">
              <div className="col-6">
                <button type="button" className="btn btn-primary" onClick={handlePreview}>Preview</button>
              </div>
              <div className="col-6 text-end">
                {
                  isAdmin ? <button type="submit" className="btn btn-success">Submit</button>
                  : <button type="submit" className="btn btn-success">Submit</button>
                }
              </div>
            </div>
            <img ref={imagePreviewRef} alt="" style={{ maxWidth: '100%' }} className="mt-3" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default BannerAdTradeForm;
