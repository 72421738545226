// components/ForgotPassword.js
import React, { useState } from 'react';
import api from '../api';
import './ForgotPassword.css'; // Import the CSS file for custom styling

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a request to the backend to initiate the password reset process
      await api.post('/forgot-password', { email });
      setMessage('Password reset email sent. Please check your email.');
    } catch (error) {
      setMessage('Error sending reset email. Please check your email and try again.');
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h5 className="text-center">Reset Password</h5>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group text-center mt-2">
                  <button type="submit" className="btn btn-primary btn-block">Reset Password</button>
                </div>
              </form>
              {message && <p className="text-center">{message}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
