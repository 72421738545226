// components/BannerAdList.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { ImageUrl } from '../global'
import { useAuth } from '../Context/AuthContext';
import BannerAdModal from './BannerAdModal';
import Pagination from './Pagination';
import { parse, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import ImageModal from './ImageModal';

function BannerAdList() {
const {isAdmin, user} =useAuth();
const [banner_ads1, setBanner_ads1] = useState([]);
const [paymentFrequency, setPaymentFrequency] = useState('weekly'); // Default to weekly
const [selectedBanner, setSelectedBanner] = useState(null); // Track the selected banner for editing
const [showEditModal, setShowEditModal] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const [expirationDate, setExpirationDate] = useState(null);
const [showImageModal, setShowImageModal] = useState(false);
const [selectedImageUrl, setSelectedImageUrl] = useState('');

const handleImageClick = (imageUrl) => {
  setSelectedImageUrl(imageUrl);
  setShowImageModal(true);
};

const handleUpdateExpirationDate = (bannerAdTrId) => {
  if (expirationDate) {
    console.log(bannerAdTrId);
    const formattedDate = format(expirationDate, 'yyyy-MM-dd HH:mm:ss');
    api.patch(`/update-expiration-date-ba/${bannerAdTrId}`, { expiration_date: formattedDate })
      .then(() => {
        // Handle success if needed
        fetchBannerAds();
        console.log('Expiration date updated successfully');
      })
      .catch((error) => {
        // Handle error if needed
        console.error('Error updating expiration date:', error);
      });
  }
};


const handleEditBanner = (banner) => {
  setSelectedBanner(banner);
  setShowEditModal(true);
};

const handleEditModalClose = () => {
  setSelectedBanner(null);
  setShowEditModal(false);
};

const handlePageChange = (page) => {
  setCurrentPage(page);
};

// async function displayRazorpay(banner_id,amount) {
//   var options = {
//     "key": "rzp_live_bAbHG1YycclGNU",//"rzp_test_rp2bOy52KhJdo7", //
//     "amount": user.country.toLowerCase() !== 'india' ? (amount *  100) : (amount * 100), // Convert amount to dollars if not from India
//     "currency": user.country.toLowerCase() !== 'india'? "USD" : "INR",
//     "name": "B2bleads.in",
//     //"description": "Test Transaction",
//     //"image": "https://example.com/your_logo",
//     "handler":async function (response){
//         const paymentVerificationResponse = await api.post('/payment', {
//           paymentId: response.razorpay_payment_id,
//           user_id:user.id,
//           user_email: user.email,
//           currency:'Inr',
//           amount:amount,
//           method:'razorpay',
//           apiresponse:response,
//           type:'banner-ad',
//           type_id:banner_id,
//         });
//     },
//     "prefill": {
//         "name": user.name,
//         "email": user.email,
//         "contact": user.mobile,
//     },
//     "theme": {
//         "color": "#3399cc"
//     }
// };
// var rzp1 = new window.Razorpay(options);
// rzp1.open();
// fetchBannerAds(user.id)
// } 

const fetchBannerAds = (userId, page) => {
  const apiUrl = isAdmin
    ? `/bannerads?page=${page}&count=8`
    : `/bannerads?filter[user_id]=${userId}&page=${page}&count=8`;

  api.get(apiUrl)
    .then((response) => {
      setBanner_ads1(response.data.data);
      setTotalPages(response.data.last_page);
    })
    .catch((error) => {
      console.error('Error fetching banner ads:', error);
    });
};

const handleBannerDelete = (banner_id) => {
  api.delete(`/banner/${banner_id}`)
    .then(() => {
      setBanner_ads1((prevBannerAds) => prevBannerAds.filter((banner) => banner.id !== banner_id));
    })
    .catch((error) => {
      console.error('Error deleting ad:', error);
    });
};

const handleLeadApprove = (banner_id) => {
  api.get(`/banner-approve/${banner_id}`)
    .then(() => {
      fetchBannerAds();
    })
    .catch((error) => {
      console.error('Error deleting lead:', error);
    });
};

// const handlePaymentFrequencyChange = (event) => {
//   console.log(event.target.value);
//   setPaymentFrequency(event.target.value);
// };

const confirmDelete = (banner_id) => {
  const isConfirmed = window.confirm("Are you sure you want to delete this banner ad?");
  if (isConfirmed) {
    handleBannerDelete(banner_id);
  }
};


useEffect(() => {
  fetchBannerAds(user.id,currentPage,paymentFrequency)
}, [user.id,currentPage,paymentFrequency]);

    return (
        <div className="container mb-5">
          <div className='d-flex justify-content-center'>
            <h2 className="my-4">Banner Ads</h2>
          </div>
          <div className='d-flex justify-content-center'>
            <ul className="list-group col-md-10">
              {banner_ads1.map((bannerad) => (
                <li key={bannerad.id} className="list-group-item">
                  <div className='row d-flex align-items-center justify-content-center text-center'>
                    <div className='col-md-2 p-2'>
                      <img src={`${ImageUrl}/storage/tradeAds/${bannerad.image_path}`} alt=" "  height='50' width='150'
                      onClick={() => handleImageClick(`${ImageUrl}/storage/tradeAds/${bannerad.image_path}`)}
                      style={{ cursor: 'pointer' }}
                       />
                    </div>
                    <div className='col-md-2 p-2'>
                      <h5>{bannerad.service_industry}</h5>
                    </div>
                    {isAdmin && (bannerad.status === 'Created' || bannerad.status === 'Closed') ? (
                      <div className='col-md-1 text-center p-2'>
                        <button className="btn btn-success btn-sm" onClick={() => handleLeadApprove(bannerad.id)}>
                          Approve
                        </button>
                      </div>
                    ) : <div className='col-md-1 p-2 text-center'>
                      {bannerad.status === 'Closed' ? (
                          <span className="text-danger">Under Review</span>
                        ) : (
                          bannerad.status
                        )}
                     </div>}
                     <div className='col-md-2 p-2 text-center"'>
                        {/* Add an Edit button to trigger the editing */}
                        <button className="btn btn-link" onClick={() => handleEditBanner(bannerad)}>
                          <FaEdit fill="blue" />
                        </button>
                        <button
                        className="btn btn-link"
                        onClick={() => confirmDelete(bannerad.id)}
                      >
                        <FaTrash fill="red" />
                      </button>
                      </div>
                    {/* <div className='col-md-1 p-2'>
                      <select value={paymentFrequency} onChange={handlePaymentFrequencyChange}>
                        <option value="10 days">10 days</option>
                        <option value="monthly">Monthly</option>
                      </select>
                    </div> */}
                  {/* <div className="col-md-2 p-2">
                    {bannerad.status === 'Created' 
                    &&(<button className="btn btn-primary btn-sm" onClick={() => displayRazorpay(bannerad.id, paymentFrequency === 'weekly' ? (user.country.toLowerCase() !== 'india' ? 5 : 400) : (user.country.toLowerCase() !== 'india' ? 12 : 1000))}>
                    {user.country.toLowerCase() !== 'india' ? `$${paymentFrequency === 'weekly' ? 5 : 12}` : `₹${paymentFrequency === 'weekly' ? 400 : 1000}`}
                  </button>
                    )}
                  </div> */}
                  {isAdmin && (
                  <div className="col-md-2">
                    <label htmlFor="expirationDate" className="form-label">
                      Expiration Date
                    </label>
                    <DatePicker
                      selected={parse( bannerad.expiration_date.replace(';', ':'), 'yyyy-MM-dd HH:mm:ss', new Date())}
                      onChange={(date) => setExpirationDate(date)}
                      onBlur={() => handleUpdateExpirationDate( bannerad.id)}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      id="expirationDate"
                    />
                  </div>
                  
                )}
                </div>
                </li>
              ))}
            </ul>
          </div>
          <div className='d-flex justify-content-center mt-2'>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </div>
          <BannerAdModal
        show={showEditModal}
        onClose={handleEditModalClose}
        banner={selectedBanner}
      />
      {showImageModal && (
        <ImageModal imageUrl={selectedImageUrl} onClose={() => setShowImageModal(false)} />
      )}
        </div>
      );
}

export default BannerAdList;
