import React from 'react';

export default function Privacy(){
    return <div class="container">
        <p><strong>PRIVACY POLICY</strong></p>
<p ><span >This Privacy Policy shall be in compliance with the General Data Protection Regulation (GDPR) in effect from 1st</span><span >&nbsp;&nbsp;</span><span >Oct 2023 and any/all provisions that may read to the contrary shall be deemed to be void and unenforceable as of that date. If you do not agree with the terms and conditions , Privacy Policy, including with the manner of collection or use of your information, please do not use or access the site. If you have any questions or concerns regarding this Privacy Policy, you should contact our Customer Support</span><span >&nbsp;</span><a href="mailto:info@b2bleads.in" ><u><span >info@b2bleads.in</span></u></a><span >&nbsp;</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;is committed to respect your online privacy. We may collect personal data but limited to Name, Firm Name, Address, Location, Email ID, Mobile Number, website, etc to provide effective services as required from time to time.&nbsp;</span><span >Paranji Enterprises</span><span >&nbsp;uses contact information to send special trade alerts, deals &amp; offers, invoices, renewal reminders occasionally promotional material.</span></p>
{/* <p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;collects Advertisement charges only through a reputed payment gateway. We use a secure payment gateway operated by one of the most reputed banks in India for collecting credit card payments. The credit card information is handled by the bank directly through its secure payment gateway. We/our server never collect Credit Card information.</span></p> */}
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;will never provide the collected information to third parties, except in the case of a requirement by law enforcement. In such a case, the requested information will be provided to appropriate government authorities upon receipt of the necessary documentation.</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;follows a rigorous anti-spam policy - including never sending unsolicited emails, not disclosing email information in private or public display, etc.</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;never gives a third-party link to any website on Trade leads at our site. Users are suggested to use send and share option to store the lead&apos;s contact details to interact with the customer directly at his/her own time. However direct link will be given to website in Advertisement space.</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;allows posting over distribution-related offers, trade leads/Ads banner</span><span >&nbsp;&nbsp;</span><span >to Service category related to Trades like logistics, packing, Air/Sea cargo, Trade exhibition events etc, Export and Import leads, Banner ad to advertisers. Therefore, personal information (such as email address, phone number) collected from registered users is allowed to share thru our network to contact concerned members mutually for the growth of their business.</span></p>
<p ><span >Please remember that any information that is disclosed in these areas becomes public information and you should exercise caution when deciding to disclose your personal information. We do not monitor any of these facilities and your use of them is at your own risk since access to contact details are absolutely free, as we look and serve in the eyes&nbsp;</span><strong><span >Traders view.</span></strong><span >&nbsp;</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;never involves in Telemarketing to users/Traders, sending irrelevant leads to his/her inbox/mobile, as we leave it to users to choose their leads and needs to share &amp; save then and there to communicate directly with the customers, without having any cumbersome mode and restrictions, as users are boss of their business.</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;will never ask for any personal information like Aadhar card, Bank details, password.</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;uses your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information... We will not sell, license or trade your personal information. We will not share your personal information with others except with law authorities, as the case may be.</span><span>&nbsp;</span></p>
<p ><span >Information collected via our server logs includes users&apos; IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization, and targeting tools to understand how users engage with our website so that we can improve it and cater personalized content/ads according to their preferences.</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;&ndash; Password precautions :</span><span >&nbsp;&nbsp;</span><span >When you sign up to become a Member, you will also be asked to choose a password. You are entirely responsible for maintaining the confidentiality of your password. It is important that you must protect it against unauthorized access to your account and information by choosing your password carefully and keeping your password and computer secure by signing out after using our services. Therefore, if your password has been compromised for any reason, you should immediately change your password.</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;treats data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Company. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.</span></p>
<p ><strong><span >Paranji Enterprises</span></strong><span >&nbsp;Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be on the Website.</span></p>
<p ><strong><span >Cancellation of Membership :</span></strong><span >&nbsp;</span><span >Any member can terminate his/her membership by log-in in to b2bleads.in Dashboard and de-activate his/her registration.</span></p>
<p ><span >This process automatically removes the member&rsquo;s profile from the directory listing or sends mail to</span><span >&nbsp;</span><a href="mailto:info@b2bleads.in" ><u><span >info@b2bleads.in</span></u></a><span >&nbsp;to opt-out. Emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek discontinuation of such communications at any point in time.</span></p>
<p ><strong><span >CONTACT US</span></strong></p>
<p ><span >If you have any questions, clarifications, grievances, or concerns regarding this privacy policy, you should contact us by sending an e-mail to</span><span >&nbsp;</span><a href="mailto:info@b2bleads.in" ><u><span >info@b2bleads.in</span></u></a><span >&nbsp;</span><span >Please note that this policy is updated as of 01st</span><span >&nbsp;&nbsp;</span><span >October 2023</span><span >&nbsp;&nbsp;</span><span >and may be changed or updated at future dates and ensure you check periodically to update on our Private policy. All rights reserved.</span></p>
<p ><strong><span >Disclaimer:</span></strong><span >&nbsp;</span><span >We are in no way responsible for any financial transactions and suggest utilizing the leads/ads with your business acumen approach. You can interact directly as you are the best judge of your trade.&nbsp;</span></p>
<p ><span >All rights reserved&reg;.</span></p>
</div>
    
}