import React from 'react';
import SearchBox from '../Searchbox/Searchbox';
import HomePageContent from '../HomePageContent';
import Carousel from 'react-bootstrap/Carousel';
import Buyers from '../../Images/Buyers.png';
import Distri from '../../Images/Distributors-Wanted.png';
import Export from '../../Images/Exporters.png';
import Import from '../../Images/Importers.png';
import Service from '../../Images/Service.png';
import WholeSale from '../../Images/Wholesalers.png';

function Home() {
    return (
        <div>
             <Carousel interval={2000} fade={true}>
             <Carousel.Item >
             <div className="d-flex bg-primary align-items-center justify-content-center">
             <div className="w-50 p-5 text-right ms-5">
              <img
                className="d-block"
                src={Buyers}
                alt="Category 1"
                style={{ maxWidth: '70%', height: '400px' }}
              />
            </div>
            <div className="w-50 text-left">
                <h1 style={{ color: 'White', }}>Free to post trade leads (visible for 7 days)</h1>
            </div>
            
          </div>
        </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex bg-success align-items-center justify-content-center">
                  <div className="w-50 p-5 text-right">
                      <img
                        className="d-block"
                        src={Distri}
                        alt="Category 2"
                        style={{ maxWidth: '70%', height: '400px' }}
                      />
                    </div>
                    <div className="w-50 text-left">
                        <h1 style={{ color: 'White'}}>4 free leads a week</h1>
                    </div>
                    
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className="d-flex bg-warning align-items-center justify-content-center">
                <div className="w-50 p-5 text-right">
                <img
                    className="d-block"
                    src={Export}
                    alt="First slide"
                    style={{ maxWidth: '70%', height: '400px' }}
                />
                </div>
                <div className="w-50 text-left">
                <h1 style={{ color: 'White' }}>Free access to all trade leads and banner ads @ zero cost</h1>
                </div>
                </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className="d-flex bg-info align-items-center justify-content-center">
                <div className="w-50 p-5 text-right">
                <img
                    className="d-block"
                    src={Import}
                    alt="First slide"
                    style={{ maxWidth: '70%', height: '400px' }}
                />
                </div>
                <div className="w-50 text-left">
                <h1 style={{ color: 'White' }}>No Membership/ package fee</h1>
                </div>
                </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className="d-flex bg-primary align-items-center justify-content-center">
                <div className="w-50 p-5 text-right">
                <img
                    className="d-block"
                    src={Service}
                    alt="First slide"
                    style={{ maxWidth: '100%', height: '400px' }}
                />
                </div>
                <div className="w-50 text-left">
                <h1 style={{ color: 'White'}}>100% verified leads/ ad banner by GST/IEC holders</h1>
                </div>
                </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className="d-flex bg-danger align-items-center justify-content-center">
                <div className="w-50 p-5 text-right">
                <img
                    className="d-block"
                    src={WholeSale}
                    alt="First slide"
                    style={{ maxWidth: '70%', height: '400px' }}
                />
                </div>
                <div className="w-50 text-left">
                <h1 style={{ color: 'White'}}>100+ trade categories and 300+ service categories</h1>
                </div>
                </div>
                </Carousel.Item>
            </Carousel>
            <SearchBox leadType = {'nill'} />
            <HomePageContent />
        </div>   
    );
}

export default Home;