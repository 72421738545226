import React from 'react';
import { Navbar, Nav, NavLink } from 'react-bootstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import './NavBar.css';

function NavBarSecondary() {
  return (
    <Navbar  variant="light" expand="lg" className='p-1'>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="w-100 justify-content-center">
          <NavLink className='nav-link col text-center' as={RouterNavLink} activeClassName="active"  to="/buy">Buy leads</NavLink>
          <NavLink className='nav-link col text-center' as={RouterNavLink} activeClassName="active" to="/sell">Sell leads</NavLink>
          <NavLink className='nav-link col text-center' as={RouterNavLink} activeClassName="active" to="/distributor">Distributorship available</NavLink>
          <NavLink className='nav-link col text-center' as={RouterNavLink} activeClassName="active" to="/dist-wanted">Distributorship wanted</NavLink>
          <NavLink className='nav-link col text-center' as={RouterNavLink} activeClassName="active" to="/export">Export Leads</NavLink>
          <NavLink className='nav-link col text-center' as={RouterNavLink} activeClassName="active" to="/import">Import Leads</NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBarSecondary;
