// components/BannerAdEditModal.js
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Assuming you are using Bootstrap for styling

const BannerAdTrEditModal = ({ bannerAd, onSave, onClose }) => {
  const [editedData, setEditedData] = useState(bannerAd);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    onSave(editedData);
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Banner Ad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3"> 
        <label htmlFor="serviceIndustry">Service Industry:</label>
        <select
          id="serviceIndustry"
          name="service_industry"
          className="form-select"
          value={editedData.service_industry}
          onChange={handleInputChange}
        >
          <option value="" disabled>Select an industry</option>
                <option value="Agri & Food Processing Machinery & Equipment">Agri & Food Processing Machinery & Equipment</option>
                <option value="Alcoholic Beverages, Tobacco & Related Products">Alcoholic Beverages, Tobacco & Related Products</option>
                <option value="Ayurvedic  & Herbal products">Ayurvedic  & Herbal products</option>
                <option value="Baby products">Baby products</option>
                <option value="Bags, Baggages & Suitcases">Bags, Baggages & Suitcases</option>
                <option value="Bar Accessories and Related Products">Bar Accessories and Related Products</option>
                <option value="Books, Magazines, Music & Entertainment">Books, Magazines, Music & Entertainment</option>
                <option value="Builders Hardware, Construction Material & Equipment">Builders Hardware, Construction Material & Equipment</option>
                <option value="Carpets, Mats, Rugs, Floorings">Carpets, Mats, Rugs, Floorings</option>
                <option value="Chemicals & Allied Products">Chemicals & Allied Products</option>
                <option value="Computer Software & Hardware">Computer Software & Hardware</option>
                <option value="Cosmetics, Toiletries  Hygiene & Personal care Products">Cosmetics, Toiletries  Hygiene & Personal care Products</option>
                <option value="Electrical Household & Other Goods, Components">Electrical Household & Other Goods, Components</option>
                <option value="Electronics & Communications">Electronics & Communications</option>
                <option value="Elevators & Escalators">Elevators & Escalators</option>
                <option value="Environment">Environment</option>
                <option value="Farm Inputs - Fertilizers, Pesticides & Seeds">Farm Inputs - Fertilizers, Pesticides & Seeds</option>
                <option value="Farm Products - Grains, Fruits Spices, etc">Farm Products - Grains, Fruits Spices, etc</option>
                <option value="Food and beverages">Food and beverages</option>
                <option value="Fish & Fish Products">Fish & Fish Products</option>
                <option value="Fitness Equipment & Sports Goods">Fitness Equipment & Sports Goods</option>
                <option value="Fuel & Energy Resources">Fuel & Energy Resources</option>
                <option value="Furniture, Interior Decoration & Furnishings">Furniture, Interior Decoration & Furnishings</option>
                <option value="Gems & Jewellery">Gems & Jewellery</option>
                <option value="Glass & Ceramics">Glass & Ceramics</option>
                <option value="Handicrafts, Gifts & Decorative">Handicrafts, Gifts & Decorative</option>
                <option value="Heating, Air Conditioner & Ventilation">Heating, Air Conditioner & Ventilation</option>
                <option value="Health and beauty">Health and beauty</option>
                <option value="Horticulture - Flowers, Plants & Related Products">Horticulture - Flowers, Plants & Related Products</option>
                <option value="Hotel & Restaurant - Equipments & Products">Hotel & Restaurant - Equipments & Products</option>
                <option value="Household & General Merchandise">Household & General Merchandise</option>
                <option value="Home Furnishings">Home Furnishings</option>
                <option value="Industrial Goods & Supplies">Industrial Goods & Supplies</option>
                <option value="Industrial Machinery & Plants & Supplies">Industrial Machinery & Plants & Supplies</option>
                <option value="Information Databases">Information Databases</option>
                <option value="Jute & Coir - Raw Material, Processed & Related Machinery">Jute & Coir - Raw Material, Processed & Related Machinery</option>
                <option value="Kitchen Utensils & Appliances">Kitchen Utensils & Appliances</option>
                <option value="Lab Instruments & Suppliers">Lab Instruments & Suppliers</option>
                <option value="Leather & Leather Products">Leather & Leather Products</option>
                <option value="Lights & Lighting">Lights & Lighting</option>
                <option value="Livestock, Poultry, Meat & Animal Products">Livestock, Poultry, Meat & Animal Products</option>
                <option value="Marine Equipment & Machinery">Marine Equipment & Machinery</option>
                <option value="Medical , HealthCare & Surgical">Medical , HealthCare & Surgical</option>
                <option value="Mineral & Metal Products">Mineral & Metal Products</option>
                <option value="Metallic Elements">Metallic Elements</option>
                <option value="Non Metallic Elements">Non Metallic Elements</option>
                <option value="Office Equipment, Stationery & Supplies">Office Equipment, Stationery & Supplies</option>
                <option value="Ores & Minerals">Ores & Minerals</option>
                <option value="Paper & Paper Products">Paper & Paper Products</option>
                <option value="Packaging products">Packaging products</option>
                <option value="Petroleum, Oil & Related Products">Petroleum, Oil & Related Products</option>
                <option value="Pets & Pet Supplies">Pets & Pet Supplies</option>
                <option value="Photographic & Optical Equipment">Photographic & Optical Equipment</option>
                <option value="Plastic Products">Plastic Products</option>
                <option value="Pollution Control Equipments">Pollution Control Equipments</option>
                <option value="Power Plants">Power Plants</option>
                <option value="Printing & Display Products">Printing & Display Products</option>
                <option value="Processed Food & Beverages Products & By Products">Processed Food & Beverages Products & By Products</option>
                <option value="Refrigeration, Cold Storage">Refrigeration, Cold Storage</option>
                <option value="Rubber & Related Items">Rubber & Related Items</option>
                <option value="Safety & Security Equipment">Safety & Security Equipment</option>
                <option value="Shipping Service">Shipping Service</option>
                <option value="Sports Goods and Entertainment">Sports Goods and Entertainment</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Textiles, Garment, Fashion Accessories & Footwear">Textiles, Garment, Fashion Accessories & Footwear</option>
                <option value="Tools & Instruments">Tools & Instruments</option>
                <option value="Toys & Fancy products">Toys & Fancy products</option>
                <option value="Transportation - Air, Rail, Auto Components">Transportation - Air, Rail, Auto Components</option>
                <option value="Watches & Clocks">Watches & Clocks</option>
                <option value="Wood - Raw Material, Processed & Related Machinery">Wood - Raw Material, Processed & Related Machinery</option> 
        </select>
        </div>
        <div className="mb-3">
              <label htmlFor="tradeLeadCategory">Trade Lead Category:</label>
              <select id="tradeLeadCategory" className="form-select"
              name="trade_lead_category" 
              value={editedData.trade_lead_category} 
              onChange={handleInputChange} 
              required>
                <option value="" disabled>Select a category</option>
                <option value="buy">Buy Lead</option>
                <option value="sell">Sell Lead</option>
                <option value="distributorship">Distributorship Available</option>
                <option value="distwanted">Distributorship Wanted</option>
                <option value="export">Export Lead</option>
                <option value="import">Import Lead</option>
              </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BannerAdTrEditModal;
