import React from 'react'
import BannerAdList from './BannerAdList'
import UserSidebar from '../UserSidebar'

function UserAds (user){
  
    return (
      <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)' }}>
      <UserSidebar />
      <div className="container">
      <BannerAdList user = {user}/>
    </div>
    </div>
    );
  
}

export default UserAds