// ImageModal.js
import React from 'react';

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img src={imageUrl} alt="Banner Ad" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
