import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar'; 
import api from '../api';
import './AdminDashboard.css'; // Import the CSS file

const AdminDashboard = () => {

  const [leadCount, setLeadCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [bannerAdCount, setBannerAdCount] = useState(0);
  const [bannerAdTradeCount, setBannerAdTradeCount] = useState(0);
  // const [visitorCount, setVisitorCount] = useState(0);

  useEffect(() => {
    // Fetch lead count
    api.get('/leads/count')
      .then(response => setLeadCount(response.data.count))
      .catch(error => console.error('Error fetching lead count:', error));

    // Fetch user count
    api.get('/users/count')
      .then(response => setUserCount(response.data.count))
      .catch(error => console.error('Error fetching user count:', error));

    // Fetch banner ad count
    api.get('/banner/count')
      .then(response => setBannerAdCount(response.data.count))
      .catch(error => console.error('Error fetching banner ad count:', error));

    // Fetch banner ad trade count
    api.get('/bannertrade/count')
      .then(response => setBannerAdTradeCount(response.data.count))
      .catch(error => console.error('Error fetching banner ad trade count:', error));

      // setVisitorCount(prevCount => prevCount + 1);
  }, []);

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'rgb(253,81,29)', backgroundColor:'white' }}>
      <Sidebar />
      <div className="content">
        <div className="box">
          <h2>Lead Count</h2>
          <p>{leadCount}</p>
        </div>
        <div className="box">
          <h2>User Count</h2>
          <p>{userCount}</p>
        </div>
        <div className="box">
          <h2>Banner Ad Count</h2>
          <p>{bannerAdCount}</p>
        </div>
        <div className="box">
          <h2>Banner Ad Trade Count</h2>
          <p>{bannerAdTradeCount}</p>
        </div>
        {/* <div className="box">
          <h2>Visitor Count</h2>
          <p>{visitorCount}</p>
        </div> */}
      </div>
    </div>
  );
};

export default AdminDashboard;